const mutations = {
    TOGGLE_PAGE_READY: (state, val) => {
        state.isPageReady = val;
    },

    TOGGLE_NAVIGATION: (state, val) => {
        state.navigationOpen = val;
    },

    TOGGLE_IS_SECTION: (state, val) => {
        state.isSection = val;
    },

    TOGGLE_IS_TRANSITION: (state, val) => {
        state.isTransition = val;
    },

    TOGGLE_DOWNLOAD_PANEL_OPEN: (state, val) => {
        state.downloadPanelOpen = val;
    },

    TOGGLE_DOWNLOAD_PANEL_ACTIVE: (state, val) => {
        state.downloadPanelActive = val;
    },

    SET_ACTIVE_CONTENT_OVERLAY: (state, val) => {
        state.activeContentOverlay = val;
    },

    TOGGLE_CONTENT_OVERLAY_OPEN: (state, val) => {
        state.contentOverlayOpen = val;
    },

    TOGGLE_COOKIE_POPUP: (state, val) => {
        state.showCookiePopup = val;
    },

    SET_CURRENT_NAV: (state, val) => {
        state.currentNav = val;
    },

    SET_CURRENT_VIDEO: (state, val) => {
        state.currentVideo = val;
    },
};

export default mutations;
