import actions from './actions';
import mutations from './mutations';
// import getters from './getters';

const state = {
    isPageReady: false,
    navigationOpen: false,
    downloadPanelOpen: false,
    downloadPanelActive: 0,
    activeContentOverlay: -1,
    contentOverlayOpen: false,
    showCookiePopup: true,
    isSection: false,
    isTransition: false,
    currentNav: 0,
    currentVideo: ''
};

const store = {
    namespaced: true,
    state,
    actions,
    mutations,
};

export default store;
