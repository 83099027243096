<template>
    <div v-bind:class="[isScroll ? 'is-scroll' : '', isScrollEnd ? 'is-scroll-end' : '']" class="c-VirtualScroll u-overflow-h">
        <div ref="content" class="c-VirtualScroll-content">
            <slot></slot>
        </div>
        <div v-if="progression" v-bind:class="{ 'is-active': dragStart }" class="c-VirtualScroll-progression u-fixed u-pos-tr u-fit-h u-hide@md">
            <div ref="line" class="c-VirtualScroll-progression-line u-absolute"></div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { pointerdown, pointermove, pointerup } from '@/utilities/pointer';
import normalizeWheel from '@/utilities/normalizeWheel';
import getPrefix from '@/utilities/getPrefix';


export default {
    name: 'VirtualScroll',

    props: {
        active: { type: Boolean, default: true },
        multiplyWheel: { type: Number, default: 1 },
        progression: { type: Boolean, default: true },
    },

    data() {
        return {
            isScroll: false,
            isScrollEnd: false,

            dragStart: false,
        };
    },

    watch: {
        active: 'onActiveChange',
    },

    computed: {
        ...mapState('window', [
            'isTouch',
        ]),
    },

    created() {
        this.scrollX = 0;
        this.scrollY = 0;

        this.x = 0;
        this.y = 0;

        this._onWheel = this.onWheel.bind(this);
        this._onTouchStart = this.onTouchStart.bind(this);
        this._onTouchMove = this.onTouchMove.bind(this);
        this._onTouchEnd = this.onTouchEnd.bind(this);

        // progression
        this.oldY = 0;
        this.startY = 0;
        this.targetY = 0;
    },

    mounted() {
        if (this.isTouch) {
            this.$el.addEventListener(pointerdown, this._onTouchStart);
            this.$el.addEventListener(pointermove, this._onTouchMove);
            window.addEventListener(pointerup, this._onTouchEnd);
        }

        this.$el.addEventListener('wheel', this._onWheel, { passive: true });

        this.$eventHub.$on('resize', this.resize);
        this.$eventHub.$on('update', this.update);

        if (this.progression) {
            this.$refs.line.addEventListener(pointerdown, this.onDragStart)
            window.addEventListener(pointermove, this.onDrag)
            window.addEventListener(pointerup, this.onDragEnd)
        }

        this.$nextTick(() => {
            this.resize();
        });
    },

    beforeDestroy() {
        if (this.isTouch) {
            this.$el.removeEventListener(pointerdown, this._onTouchStart);
            this.$el.removeEventListener(pointermove, this._onTouchMove);
            window.removeEventListener(pointerup, this._onTouchEnd);
        }

        this.$el.removeEventListener('wheel', this._onWheel);

        this.$eventHub.$off('resize', this.resize);
        this.$eventHub.$off('update', this.update);

        if (this.progression) {
            this.$refs.line.removeEventListener(pointerdown, this.onDragStart)
            window.removeEventListener(pointermove, this.onDrag)
            window.removeEventListener(pointerup, this.onDragEnd)
        }
    },

    methods: {
        resize() {
            this.contentSize = {
                x: this.$refs.content.offsetWidth,
                y: this.$refs.content.offsetHeight,
            };

            this.containerSize = {
                x: this.$el.offsetWidth,
                y: this.$el.offsetHeight,
            };

            this.toggleClass();

            if (this.progression) {
                this.progHeight = Math.round((this.containerSize.y / this.contentSize.y) * this.containerSize.y);
                this.$refs.line.style.height = this.progHeight - 4 + 'px';
            }
        },

        onWheel(event) {
            if (!this.active) return;

            // if (this.scrollY > 0 && this.scrollY < this.contentSize.y - this.containerSize.y) {
            //     event.preventDefault();
            //     event.stopPropagation();
            // }

            this.wheelVal = normalizeWheel(event);

            this.scrollX += this.wheelVal.pixelX * this.multiplyWheel;
            this.scrollY += this.wheelVal.pixelY * this.multiplyWheel;

            this.safePosition();

            this.toggleClass();
        },

        onTouchStart(event) {
            if (!this.active) return;

            this.touchStart = true;
            this.$el.classList.add('u-select-none');

            this.pointerEvent = this.isTouch && event.type !== 'mousedown' ? (event.touches[0] || event.changedTouches[0]) : event;
            this.oldDeltaY = this.scrollY;

            this.startDeltaY = this.pointerEvent.clientY;
        },

        onTouchMove(event) {
            if (!this.touchStart) return;

            this.pointerEvent = this.isTouch && event.type !== 'mousemove' ? (event.touches[0] || event.changedTouches[0]) : event;

            this.scrollY = this.oldDeltaY - ((this.pointerEvent.clientY - this.startDeltaY) * 1.8);

            this.safePosition();

            this.toggleClass();
        },

        onTouchEnd() {
            this.touchStart = false;
            this.$el.classList.remove('u-select-none');
        },

        toggleClass() {
            this.isScroll = (this.scrollY > 1);
            this.isScrollEnd = (this.scrollY >= (this.contentSize.y - this.containerSize.y));
        },

        onActiveChange() {
            this.scrollY = 0;
            this.scrollY = 0;

            this.x = 0;
            this.y = 0;

            this.toggleClass();
        },

        goToScroll(x, y, noAnim = false) {
            this.scrollX = x;
            this.scrollY = y;

            this.safePosition();

            if(noAnim) {
                this.x = this.scrollX
                this.y = this.scrollY
            }
        },

        safePosition() {
            // Scroll X
            // -----
            if (this.scrollX > this.contentSize.x - this.containerSize.x) {
                this.scrollX = this.contentSize.x - this.containerSize.x;
            }
            if (this.scrollX < 0) {
                this.scrollX = 0;
            }

            // Scroll Y
            // -----
            if (this.scrollY > this.contentSize.y - this.containerSize.y) {
                this.scrollY = this.contentSize.y - this.containerSize.y;
            }
            if (this.scrollY < 0) {
                this.scrollY = 0;
            }
        },

        update() {
            if (!this.active) return;

            this.x += (this.scrollX - this.x) * 0.2;
            this.x = Math.round(this.x * 1000) / 1000;
            this.y += (this.scrollY - this.y) * 0.2;
            this.y = Math.round(this.y * 1000) / 1000;

            this.$refs.content.style[getPrefix('transform')] = `translate3d(${-this.x}px, ${-this.y}px, 0px)`;

            if (this.progression && this.contentSize.y !== this.containerSize.y) {
                this.ratio = this.y / (this.contentSize.y - this.containerSize.y);
                this.progY = this.ratio * (this.containerSize.y - this.progHeight);
                this.$refs.line.style[getPrefix('transform')] = `translateY(${this.progY}px) translateZ(0)`;
            }
        },

        onDragStart() {
            this.dragStart = true;
            this.$el.classList.add('u-select-none');
            this.pointerEvent = this.isTouch && event.type !== 'mousedown' ? (event.touches[0] || event.changedTouches[0]) : event;

            this.oldY = this.progY;
            this.startY = this.pointerEvent.pageY;
        },

        onDrag() {
            if (!this.dragStart) return;

            this.pointerEvent = this.isTouch && event.type !== 'mousemove' ? (event.touches[0] || event.changedTouches[0]) : event;
            this.targetY = this.oldY + (this.pointerEvent.pageY - this.startY);

            this.targetY =  this.targetY * (this.containerSize.y / this.progHeight)

            this.goToScroll(0, this.targetY);
        },

        onDragEnd() {
            this.dragStart = false;
            this.$el.classList.remove('u-select-none');
        },

    },
};
</script>

<style lang="stylus">
    .c-VirtualScroll {
        &-content {
            transform translateZ(0)
            will-change transform
        }

        &-progression {
            width 16px
            height 100%

            &.is-active {
                &:before {
                    opacity 1
                }
            }

            &:before {
                content ""
                position absolute
                top 0
                left 0
                width 100%
                height 100%
                background rgba(white, 0.5)
                border-left 1px solid #dfdfdf
                border-right 1px solid rgba(white, 0.3)

                opacity 0
                transform translateZ(0)
                transition opacity 0.6s $ease-out-quart
            }

            &-line {
                top 2px
                right 2px

                width 7px
                background black
                border-radius 6px

                opacity 0.5

                transform-origin top
                transform  translateZ(0)

                .is-active &, &:hover {
                    width 11px
                }
            }
        }
    }
</style>
