import Vue from 'vue'
import Vuex from 'vuex'

import moduleMain from './main/index';
import moduleWindow from './window/index';

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        main: moduleMain,
        window: moduleWindow,
    },
    strict: true,
});
