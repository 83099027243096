import 'intersection-observer'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
}

Vue.prototype.$eventHub = new Vue(); // Global event bus

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
