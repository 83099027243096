export default function getOffsets(el) {
    let offsetTop  = 0;
    let offsetLeft = 0;

    do {
        offsetTop += el.offsetTop;
        offsetLeft += el.offsetLeft;

        el = el.offsetParent;
    } while( el );

    return { top: offsetTop, left: offsetLeft };
}
