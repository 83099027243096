<template>
    <div v-on:click="onClick($event)" v-on:mouseenter="onMouseEnter" v-on:mousemove="onMouseMove" v-on:mouseleave="onMouseLeave" >
        <slot></slot>

        <div ref="cursor" v-bind:class="{ 'is-cursor-active': isActive }" class="c-CursorFollow u-absolute u-pos-tl">
            <slot name="cursor"></slot>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import getPrefix from '@/utilities/getPrefix';
    import getOffsets from '@/utilities/getOffsets';

    export default {
        name: 'CursorFollow',

        props: {
            onClick: { type: Function, default: () => this },
            initX: { type: Number, default: 0.5 },
            initY: { type: Number, default: 0.5 },
            reposition: { type: Boolean, default: false },
            autoHide: { type: Boolean, default: true },
        },

        data() {
            return {
                isActive: false,
            };
        },

        computed: {
            ...mapState('window', [
                'isTouch'
            ])
        },

        created() {
            this.x = 0;
            this.targetX = 0;

            this.y = 0;
            this.targetY = 0;

            this.firstTime = true;
        },

        mounted() {
            if (!this.autoHide) {
                this.isActive = true;
            }

            this.resize();

            this.x = this.targetX = this.posInit.x;
            this.y = this.targetY = this.posInit.y;

            this.$eventHub.$on('resize', this.resize);
            this.$eventHub.$on('update', this.update);
        },

        beforeDestroy() {
            this.$eventHub.$off('resize', this.resize);
            this.$eventHub.$off('update', this.update);
        },

        methods: {
            resize() {
                this.offsets = getOffsets(this.$el);

                this.width = this.$el.offsetWidth;
                this.height = this.$el.offsetHeight;

                this.cursorWidth = this.$refs.cursor.offsetWidth;
                this.cursorHeight = this.$refs.cursor.offsetHeight;

                this.posInit = {
                    x: this.width * this.initX, // - (this.cursorWidth / 2),
                    y: this.height * this.initY, // - (this.cursorHeight / 2),
                };
            },

            onMouseEnter() {
                this.isActive = true;
            },

            onMouseMove(event) {
                if(this.isTouch) return;

                this.pointerEvent = this.isTouch && event.type != 'mousemove' ? (event.touches[0] || event.changedTouches[0]) : event;

                this.targetX = this.pointerEvent.pageX - this.offsets.left;
                this.targetY = this.pointerEvent.pageY - this.offsets.top;

                if (this.firstTime) {
                    this.firstTime = false;

                    this.x = this.targetX;
                    this.y = this.targetY;
                }
            },

            onMouseLeave() {
                if(this.isTouch) return;

                if (this.autoHide) {
                    this.isActive = false;
                }

                if (this.reposition) {
                    this.targetX = this.posInit.x;
                    this.targetY = this.posInit.y;
                }
            },

            update() {
                this.x += (this.targetX - this.x) * 0.1;
                this.y += (this.targetY - this.y) * 0.1;

                this.$refs.cursor.style[getPrefix('transform')] = `translate3d(${this.x}px, ${this.y}px, 0px)`;
            },
        },
    }
</script>

<style lang="stylus">
    .c-CursorFollow {
        will-change: transform;
        transform translateZ(0)
        pointer-events none
    }
</style>