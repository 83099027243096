<template>
    <div id="app" v-bind:class="{ 'is-touch': isTouch, 'is-loaded': isLoaded, 'is-transition': isTransition }" class="u-relative u-fit-w u-overflow-h">
        <background-webgl />

        <smooth-scroll>
            <transition mode="in-out" v-on:before-enter="transitionBeforeEnter" v-on:enter="transitionEnter" v-on:after-enter="transitionAfterEnter" v-on:before-leave="transitionBeforeLeave" v-on:leave="transitionLeave" v-on:after-leave="transitionAfterLeave">
                <router-view />
            </transition>
        </smooth-scroll>

        <my-header />

        <my-navigation />

        <navigation-btn />

        <template v-if="$route.name === 'Sections'">
            <download-panel v-bind:title="titleCountryReports" v-bind:content="contentCountryReports" v-bind:active="downloadPanelOpen && downloadPanelActive === 0" class="is-not-video" />
            <download-panel v-bind:type="'overlay'" v-bind:title="titleBarometerVideos" v-bind:content="contentBarometerVideos" v-bind:active="(downloadPanelOpen && downloadPanelActive === 1) || contentOverlayOpen" v-bind:displayIcon="false" />
            <download-panel v-bind:title="titlePreviousReports" v-bind:content="contentPreviousReports" v-bind:active="downloadPanelOpen && downloadPanelActive === 2" class="is-not-video" />
        </template>

        <orientation-view v-if="showOrientationView && isTouch" />

        <my-cursor />

        <my-loading v-if="!pageLoaded" />

        <!-- <cookie-popup v-if="showCookiePopup" /> -->

        <div v-if="displayGrid" class="c-Grid c-Container u-fixed u-pos-tl u-fit-w u-viewport-fit-h u-flex">
            <div class="u-relative u-fit">
                <div class="c-Grid-line u-absolute u-pos-tl"></div>
                <div class="c-Grid-line u-absolute u-pos-tl u-offset-l-w1of16"></div>
                <div class="c-Grid-line u-absolute u-pos-tl u-offset-l-w2of16"></div>
                <div class="c-Grid-line u-absolute u-pos-tl u-offset-l-w3of16"></div>
                <div class="c-Grid-line u-absolute u-pos-tl u-offset-l-w4of16"></div>
                <div class="c-Grid-line u-absolute u-pos-tl u-offset-l-w5of16"></div>
                <div class="c-Grid-line u-absolute u-pos-tl u-offset-l-w6of16"></div>
                <div class="c-Grid-line u-absolute u-pos-tl u-offset-l-w7of16"></div>
                <div class="c-Grid-line u-absolute u-pos-tl u-offset-l-w8of16"></div>
                <div class="c-Grid-line u-absolute u-pos-tl u-offset-l-w9of16"></div>
                <div class="c-Grid-line u-absolute u-pos-tl u-offset-l-w10of16"></div>
                <div class="c-Grid-line u-absolute u-pos-tl u-offset-l-w11of16"></div>
                <div class="c-Grid-line u-absolute u-pos-tl u-offset-l-w12of16"></div>
                <div class="c-Grid-line u-absolute u-pos-tl u-offset-l-w13of16"></div>
                <div class="c-Grid-line u-absolute u-pos-tl u-offset-l-w14of16"></div>
                <div class="c-Grid-line u-absolute u-pos-tl u-offset-l-w15of16"></div>
                <div class="c-Grid-line u-absolute u-pos-tl u-offset-l-w16of16"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import WebFont from 'webfontloader';
    import throttle from '@/utilities/throttle';

    import { gsap, Quart } from 'gsap';

    import SmoothScroll from '@/components/SmoothScroll';
    import MyHeader from '@/components/MyHeader';
    import MyNavigation from '@/components/MyNavigation';
    import MyCursor from '@/components/MyCursor';
    import MyLoading from '@/components/MyLoading';
    import NavigationBtn from '@/components/NavigationBtn';
    import BackgroundWebgl from '@/components/BackgroundWebgl';
    import DownloadPanel from '@/components/DownloadPanel';
    // import CookiePopup from '@/components/CookiePopup';
    import OrientationView from '@/components/OrientationView';

    export default {
        name: 'App',

        components: {
            SmoothScroll,
            MyHeader,
            MyNavigation,
            MyCursor,
            MyLoading,
            NavigationBtn,
            BackgroundWebgl,
            DownloadPanel,
            // CookiePopup,
            OrientationView,
        },

        data() {
            return {
                isLoaded: false,
                displayGrid: false,

                // Download Panels Data
                // ---------

                titleCountryReports: 'Download country specific reports',
                contentCountryReports: [
                    {
                        label: 'GE Innovation Barometer 2020  |  Brazil',
                        cursor: 'download',
                        url: 'https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2020-Brazil_Country_Report.pdf'
                    },
                    {
                        label: 'GE Innovation Barometer 2020  |  China',
                        cursor: 'download',
                        url: 'https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2020-China_Country_Report.pdf'
                    },
                    {
                        label: 'GE Innovation Barometer 2020  |  France',
                        cursor: 'download',
                        url: 'https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2020-France_Country_Report.pdf'
                    },
                    {
                        label: 'GE Innovation Barometer 2020  |  India',
                        cursor: 'download',
                        url: 'https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2020-India_Country_Report.pdf'
                    },
                    {
                        label: 'GE Innovation Barometer 2020  |  Poland',
                        cursor: 'download',
                        url: 'https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2020-Poland_Country_Report.pdf'
                    },
                    {
                        label: 'GE Innovation Barometer 2020  |  Saudi Arabia',
                        cursor: 'download',
                        url: 'https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2020-Saudi_Arabia_Country_Report.pdf'
                    },
                    {
                        label: 'GE Innovation Barometer 2020  |  South Africa',
                        cursor: 'download',
                        url: 'https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2020-South_Africa_Country_Report.pdf'
                    },
                    {
                        label: 'GE Innovation Barometer 2020  |  UAE',
                        cursor: 'download',
                        url: 'https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2020-UAE_Country_Report.pdf'
                    },
                    {
                        label: 'GE Innovation Barometer 2020  |  USA',
                        cursor: 'download',
                        url: 'https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2020-USA_Country_Report.pdf'
                    },
                    {
                        label: 'GE Innovation Barometer 2020  |  Vietnam',
                        cursor: 'download',
                        url: 'https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2020-Vietnam_Country_Report.pdf'
                    },
                ],

                titleBarometerVideos: 'Watch all the 2020 Global Innovation Barometer Videos Interviews',
                contentBarometerVideos: [
                    {
                        label: 'Susan Dentzer, Health policy analyst',
                        cursor: 'play',
                        youtubeId: 'hMrdq02LmZY',
                        poster: '/static/images/video_01.jpg'
                    },
                    {
                        label: 'Rita Gunther McGrath, Strategic management scholar',
                        cursor: 'play',
                        youtubeId: 'xAEkzNYKBcw',
                        poster: '/static/images/video_02.jpg'
                    },
                    {
                        label: 'Paul Haenle, Political adviser',
                        cursor: 'play',
                        youtubeId: '0NaXxMBH2VQ',
                        poster: '/static/images/video_03.jpg'
                    },
                    {
                        label: 'Chad Bown, Economist',
                        cursor: 'play',
                        youtubeId: 'ZeeO3zbGQBs',
                        poster: '/static/images/video_04.jpg'
                    },
                    {
                        label: 'Jim Pethokoukis, Economic analyst',
                        cursor: 'play',
                        youtubeId: 'HaIUMwHT1WA',
                        poster: '/static/images/video_05.jpg'
                    }
                ],

                titlePreviousReports: 'Download reports from previous years',
                contentPreviousReports: [
                    {
                        label: 'GE Innovation Barometer 2012  |  full report',
                        cursor: 'download',
                        url: 'https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2012-Full_Report.pdf'
                    },
                    {
                        label: 'GE Innovation Barometer 2013  |  summary report',
                        cursor: 'download',
                        url: 'https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2013-Summary_Report.pdf'
                    },
                    {
                        label: 'GE Innovation Barometer 2014  |  full report',
                        cursor: 'download',
                        url: 'https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2014-Full_Report.pdf'
                    },
                    {
                        label: 'GE Innovation Barometer 2014  |  summary report',
                        cursor: 'download',
                        url: 'https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2014-Summary_Report.pdf'
                    },
                    {
                        label: 'GE Innovation Barometer 2016  |  full report',
                        cursor: 'download',
                        url: 'https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2016-Full_Report.pdf'
                    },
                    {
                        label: 'GE Innovation Barometer 2016  |  summary report',
                        cursor: 'download',
                        url: 'https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2016-Summary_Report.pdf'
                    },
                    {
                        label: 'GE Innovation Barometer 2018  |  full report',
                        cursor: 'download',
                        url: 'https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2018-Full_Report.pdf'
                    },
                    {
                        label: 'GE Innovation Barometer 2018  |  summary report',
                        cursor: 'download',
                        url: 'https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2018-Summary_Report.pdf'
                    },
                ],
            };
        },

        computed: {
            ...mapState('window', [
                'isTouch',
                'height',
                'minHeight',
                'showOrientationView',
            ]),

            ...mapState('main', [
                'navigationOpen',
                'isTransition',
                'downloadPanelActive',
                'downloadPanelOpen',
                // 'showCookiePopup',
                'contentOverlayOpen',
            ]),

            ...mapGetters('window', [
                'isMobile'
            ]),
        },

        watch: {
            $route: 'onRouteChange',
        },

        created() {
            this.transitionEnded = true;
            this.pageLoaded = false;

            this.$body = document.querySelector('body');

            // Listeners
            // --------------------
            window.addEventListener('resize', throttle(this.resize), false);
            window.addEventListener('orientationchange', throttle(this.resize, 1000), true);

            // Load fonts
            // --------------------
            WebFont.load({
                custom: {
                    families: ['GE Inspira Sans', 'GE Inspira Sans Bold', 'GE Inspira Sans Italic'],
                },
                active:() => {
                    this.fontLoaded = true;
                    this.onPageReady();
                }
            });

            // Page ready: hide loader
            // --------------------
            this.$eventHub.$on('page:enter', () => {
                this.pageMounted = true;
                this.onPageReady();
            });

            this.$eventHub.$on('page:loaded', () => {
                this.pageLoaded = true;
                this.onPageReady();
            });

            this.$router.beforeEach((to, from, next) => {
                this.from = from;
                this.to = to;

                this.transitionType = 'fade'

                if (!this.navigationOpen && this.from && this.to && ((this.from.name === 'Home' && this.to.name === 'Sections') || (this.from.meta.type === 'section' && this.to.meta.type === 'section'))) {
                    this.transitionType = 'up'
                }

                this.oldScroll = window.scrollY;

                next();
            });

            this.$nextTick(() => {
                this.pageMounted = true;
                this.onPageReady();
            });
        },

        mounted() {
            requestAnimationFrame(this.update.bind(this));
        },

        methods: {
            ...mapActions('window', {
                'resizeStore': 'resize',
                'toggleShowOrientationView': 'resize',
            }),

            ...mapActions('main', [
                'togglePageReady',
                'toggleNavigation',
                'toggleIsTransition'
            ]),

            resize() {
                this.resizeStore();

                if (window.matchMedia("(orientation: landscape) and (max-device-height : 896px)").matches) {
                    this.toggleShowOrientationView(true);
                } else {
                    this.toggleShowOrientationView(false);
                }

                this.$eventHub.$emit('resize');
            },

            update() {
                this.$eventHub.$emit('update');
                requestAnimationFrame(this.update.bind(this));
            },

            onRouteChange(to, from) {
                this.$root.url = encodeURIComponent(window.location.origin + window.location.pathname);

                this.$body.classList.add('is-'+to.name);

                if (from.name) {
                    this.$body.classList.remove('is-'+from.name);
                }
            },

            onPageReady() {
                if (this.fontLoaded && this.pageMounted && this.transitionEnded && this.pageLoaded) {
                    this.$eventHub.$emit('scrollSection:update');
                    this.$eventHub.$emit('header:reset');
                    this.resize();

                    this.$nextTick(() => {
                        this.isLoaded = true;
                        this.togglePageReady(true);
                        this.$eventHub.$emit('page:ready');
                        this.$eventHub.$emit('header:show');
                    });
                }
            },

            // before

            transitionBeforeEnter(el) {
                if (this.transitionType === 'up' || (this.isTouch && this.transitionType)) {
                    el.style.position = 'fixed';
                    el.style.top = 0;
                    el.style.left = 0;
                    el.style.width = '100%';
                    // el.style.zIndex = '90';
                    // el.style.pointerEvents = 'none';
                    // el.style.overflow = 'hidden';

                    if(this.$root.smoothScroll > this.height) {
                        this.$eventHub.$emit('header:hide');
                    }
                }
            },

            transitionEnter(el, done) {
                done()
            },

            transitionAfterEnter(el) {
                if (this.isTouch || this.transitionType === 'up') {
                    this.$to = el;
                }
            },

            // after

            transitionBeforeLeave(el) {
                el.style.position = 'fixed';
                el.style.top = 0;
                el.style.left = 0;
                el.style.width = '100%';
                el.style.zIndex = '100';
                el.style.pointerEvents = 'none';
                el.style.overflow = 'hidden';

                if (this.isTouch) {
                    el.style.position = 'absolute';
                    this.$el.style.height = this.$root.scrollHeight + 'px';
                } else {
                    el.style.height = this.height + 'px';
                }

                window.scrollTo(0, this.oldScroll);
                this.$root.smoothScroll = this.oldScroll;

                this.pageMounted = false;
                this.transitionEnded = false;

                if (!this.to || !this.to.meta.type) {
                    this.$eventHub.$emit('webgl:play')
                }

                this.toggleIsTransition(true);
                this.toggleNavigation(false);

                this.$eventHub.$emit('cursor:reset');
            },


            transitionLeave(el, done) {
                let offset = 0;
                let opacity = 0;

                if (this.transitionType === 'up') {
                    offset = -this.height;
                    opacity = 1;
                }

                gsap.to(el, 0.8, {
                    y: offset,
                    alpha: opacity,
                    ease: Quart.easeInOut,
                    onComplete: () => {
                        el.style.visibility = 'hidden';

                        done()
                    }
                });
            },

            transitionAfterLeave() {
                window.scrollTo(0, 0);
                this.$root.smoothScroll = 0;

                this.toggleIsTransition(false);

                if (this.isTouch || (this.transitionType === 'up' && this.$to)) {
                    gsap.set(this.$to, { clearProps: 'all' });
                }

                if (this.isTouch) {
                    this.$el.style.height = 'auto';
                }

                setTimeout(() => {
                    this.transitionEnded = true;
                    this.onPageReady();
                }, 40);
            }
        },
    };
</script>

<style lang="stylus">
    @import './styles/styles.styl'

    .c-Grid {
        z-index 10001
        pointer-events none

        &-line {
            height 100%
            width 1px
            background red
        }

        &:after {
            content ""
            position absolute
            top 50%
            left 0
            width 100%
            height 1px
            background red
        }
    }

</style>