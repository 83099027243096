const mutations = {
    SET_WIDTH: (state, val) => {
        state.width = val;
    },
    SET_HEIGHT: (state, val) => {
        state.height = val;
    },
    SET_ISTOUCH: (state, val) => {
        state.isTouch = val;
    },
    SET_MINHEIGHT: (state, val) => {
        state.minHeight = val;
    },
    TOGGLE_SHOW_ORIENTATION_VIEW: (state, val) => {
        state.showOrientationView = val;
    },
};

export default mutations;
