<template>
    <div class="c-SmoothScroll">
        <div ref="fakeScroll" class="js-fake-scroll c-SmoothScroll-fake"></div>

        <div ref="content" class="c-SmoothScroll-content" v-bind:class="[ (isTouch || isIE) ? 'u-relative u-fit-w' : 'u-fixed u-pos-tl u-fit-w' ]">
            <slot></slot>
            <div v-if="progression" ref="line" class="c-SmoothScroll-progression u-fixed u-pos-tl u-fit-w"></div>
        </div>
    </div>
</template>

<script>
    // Reminder: before initialize this component:
    // --------------
    // - Don't forget to add a webfont loader (the line height is different between fonts)
    // - Don't use height auto for image
    //
    // If you don't do that, when the size of page will be calculate, the height risked to be incorrect.

    import { mapState } from 'vuex';
    import getOffsetTop from '@/utilities/getOffsetTop';
    import getPrefix from '@/utilities/getPrefix';

    export default {
        name: 'SmoothScroll',

        props: {
            securityCulling: { type: Number, default: 1 },
            toggleVisibility: { type: Boolean, default: false },
            progression: { type: Boolean, default: false },
        },

        computed: {
            ...mapState('window', [
                'height',
                'isTouch',
                'isIE',
            ]),
        },

        watch: {
            $route: 'onRouteChange',
        },

        created() {
            this.$root.scrollTop = 0;
            this.$root.smoothScroll = 0;
            this.cuePoints = [];

            this.ratio = 0;
        },

        mounted() {
            document.addEventListener('scroll', this.onScroll, false);

            this.$eventHub.$on('resize', this.resize);
            this.$eventHub.$on('update', this.update);

            this.$eventHub.$on('scrollSection:update', this.setCuePoints);
        },

        beforeDestroy() {
            document.removeEventListener('scroll', this.onScroll);

            this.$eventHub.$off('resize', this.resize);
            this.$eventHub.$off('update', this.update);

            this.$eventHub.$off('scrollSection:update', this.setCuePoints);
        },

        methods: {
            onRouteChange() {
                // Reset scroll val
                window.scrollTo(0, 0);
                this.$root.scrollTop = 0;
                this.$root.smoothScroll = 0;
                this.ratio = 0;

                if (this.progression) {
                    this.$refs.line.style[getPrefix('transform')] = `scaleX(${this.ratio}) translateZ(0)`;
                }
            },

            onScroll() {
                this.$root.scrollTop = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

                if (!this.isTouch && !this.isIE && this.$root.scrollHeight) {
                    clearTimeout(this.timerScroll);

                    document.body.style.pointerEvents = 'none';

                    this.timerScroll = setTimeout(() => {
                        document.body.style.pointerEvents = null;
                    }, 200);
                }
            },

            resize() {
                this.$root.scrollHeight = this.$refs.content.clientHeight;

                if (this.$root.scrollHeight !== this.oldscrollHeight) {
                    this.setPageHeight();
                }

                this.oldscrollHeight = this.$root.scrollHeight;

                // ------------

                this.cuePoints.forEach( (section) => {
                    section.size = section.$el.offsetHeight;
                    section.offset = getOffsetTop(section.$el);

                    section.enabled = false;
                });
            },

            setPageHeight() {
                if (!this.isTouch && !this.isIE) {
                    this.$refs.fakeScroll.style.height = this.$root.scrollHeight + 'px';
                } else { // if mobile, transform val
                    this.$refs.fakeScroll.style.height = '0px';

                    for (let i = 0; i < this.NB_CUEPOINTS; i++) {
                        this.cuePoints[i].$el.style[getPrefix('transform')] = 'none';
                    }
                }
            },

            setCuePoints() {
                this.cuePoints = [];

                const sections = Array.from(this.$refs.content.querySelectorAll('.js-section'));

                sections.forEach((el) => {
                    let section = {
                        $el: el,
                        y: 0,
                        size: 0,
                        offset: 0,
                        enabled: false,
                    };

                    if (this.toggleVisibility && !this.isTouch) {
                        el.style.visibility = 'hidden';
                    }

                    this.cuePoints.push(section);
                });

                this.NB_CUEPOINTS = this.cuePoints.length;
            },

            update() {
                if (this.isTouch || this.isIE || !this.$root.scrollHeight) {
                    this.$root.smoothScroll = this.$root.scrollTop;
                } else {
                    this.$root.smoothScroll += ( this.$root.scrollTop - this.$root.smoothScroll ) * 0.1;
                    this.$root.smoothScroll = Math.round(this.$root.smoothScroll * 100) / 100;

                    for (this.i = 0; this.i < this.NB_CUEPOINTS; this.i++) {
                        this.cuePoints[this.i].y = -this.$root.smoothScroll;

                        if (((this.cuePoints[this.i].y + this.cuePoints[this.i].offset + this.cuePoints[this.i].size) + this.securityCulling < 0
                            || (this.cuePoints[this.i].y + this.cuePoints[this.i].offset - this.height) - this.securityCulling > 0 )) {

                            if (this.cuePoints[this.i].enabled) {
                                if(this.toggleVisibility){
                                    this.cuePoints[this.i].$el.style.visibility = 'hidden';
                                }

                                this.cuePoints[this.i].enabled = false;
                                this.cuePoints[this.i].$el.style[getPrefix('transform')] = `translate3d(0px, ${this.cuePoints[this.i].y}px, 0px)`;
                            }
                        } else {
                            if (!this.cuePoints[this.i].enabled) {
                                if(this.toggleVisibility){
                                    this.cuePoints[this.i].$el.style.visibility = 'visible';
                                }
                                this.cuePoints[this.i].enabled = true;
                            }

                            this.cuePoints[this.i].$el.style[getPrefix('transform')] = `translate3d(0px, ${this.cuePoints[this.i].y}px, 0px)`;
                        }

                    }


                    if (this.progression && this.$root.scrollHeight !== this.height) {
                        this.ratio = this.$root.smoothScroll / (this.$root.scrollHeight - this.height);
                        this.ratio = Math.round(this.ratio * 100) / 100;
                        this.$refs.line.style[getPrefix('transform')] = `scaleX(${this.ratio}) translateZ(0)`;
                    }
                }
            }
        },
    }
</script>

<style lang="stylus">
    .c-SmoothScroll {
        &-progression {
            height 4px
            background white

            transform-origin left
            transform scaleX(0) translateZ(0)

            z-index 100
        }

        &-fake {

        }
    }

    .js-section {
        position relative
        transform translateZ(0)
        will-change: transform;
    }
</style>