let oldWidth = window.innerWidth;

const actions = {
    resize: (context) => {
        context.commit('SET_ISTOUCH', 'ontouchstart' in document.documentElement);

        if ('ontouchstart' in document.documentElement && oldWidth !== window.innerWidth) {
            oldWidth = window.innerWidth;
            context.commit('SET_MINHEIGHT', window.innerHeight);
        }

        if (window.matchMedia("(orientation: landscape) and (max-device-height : 896px)").matches) {
            context.commit('TOGGLE_SHOW_ORIENTATION_VIEW', true);
        } else {
            context.commit('TOGGLE_SHOW_ORIENTATION_VIEW', false);
        }

        context.commit('SET_WIDTH', window.innerWidth);
        context.commit('SET_HEIGHT', window.innerHeight);
    },
};

export default actions;
