<template>
    <div v-on:mouseenter.passive="onMouseEnter" v-on:mouseleave.passive="onMouseLeave">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'CursorTrigger',

        props: {
            active: { type: Boolean, default: true },
            name: { type: String, default: '' },
            value: { type: String, default: '' },
            theme: { type: String, default: 'light' },
        },

        watch: {
            'active': 'onActiveChange'
        },

        methods: {
            onMouseEnter() {
                if (!this.active) return;

                this.$eventHub.$emit('cursor:enter', this.name, this.value, this.theme);
            },

            onMouseLeave() {
                if (!this.active) return;

                this.$eventHub.$emit('cursor:leave', this.name, this.value);
            },

            onActiveChange() {
                if (!this.active) {
                    this.$eventHub.$emit('cursor:leave', this.name, this.value);
                }
            }
        }
    }
</script>