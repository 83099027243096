<template>
    <div v-bind:class="activeClass" class="c-Cursor u-fixed u-pos-tl u-pointer-none | u-hide@sm">
        <div class="c-Cursor-scroll u-absolute u-pos-tl u-flex u-align-items-c u-justify-content-c">
            <img :src="`${publicPath}static/images/icon-scroll.png`" alt="" width="7" height="13">
        </div>

        <div class="c-Cursor-view u-absolute u-pos-tl u-flex u-align-items-c u-justify-content-c u-bg--blue-light">
            <span class="t-text--xs u-color--white">View</span>
        </div>

        <div class="c-Cursor-download u-absolute u-pos-tl u-flex u-align-items-c u-justify-content-c u-bg--blue-light">
            <img :src="`${publicPath}static/images/icon-download.png`" alt="" width="25" height="25">
        </div>

        <div class="c-Cursor-play u-absolute u-pos-tl u-flex u-align-items-c u-justify-content-c u-bg--blue-light">
            <img :src="`${publicPath}static/images/icon-play.png`" alt="" width="25" height="25">
        </div>

        <div v-bind:class="{ 'u-bg--black' : theme === 'dark', 'u-bg--white' : theme !== 'dark' }" class="c-Cursor-graph u-absolute u-pos-tl u-flex u-align-items-c u-justify-content-c">
            <span v-bind:class="{ 'u-font-base u-color--white' : theme === 'dark', 'u-font-bold u-color--black' : theme !== 'dark' }" class="t-text--sm">{{value}}</span>
        </div>

        <div v-bind:class="{ 'is-touch': isTouch }" class="c-Cursor-drag u-absolute u-pos-tl u-flex u-align-items-c u-justify-content-c">
            <span class="c-Cursor-drag-label u-relative t-text--sm u-color--black">Drag</span>
            <div class="c-Cursor-drag-icon u-absolute u-flex u-align-items-c u-justify-content-sb">
                <span class="c-Cursor-drag-icon-arrow">
                    <span class="c-Cursor-drag-icon-arrow-line"></span>
                </span>
                <span class="c-Cursor-drag-icon-arrow">
                    <span class="c-Cursor-drag-icon-arrow-line"></span>
                </span>
            </div>
        </div>

        <div class="c-Cursor-spin u-absolute u-pos-tl u-flex u-align-items-c u-justify-content-c">
            <span class="c-Cursor-spin-label u-relative t-text--sm u-color--black">Spin</span>
            <div class="c-Cursor-spin-icon u-absolute u-pos-tl u-fit u-flex u-align-items-c u-justify-content-c">
                <img class="c-Cursor-spin-icon-img u-block u-force-3d" :src="`${publicPath}static/images/icon-spin.png`" alt="" width="64" height="56">
            </div>
        </div>

        <div class="c-Cursor-click u-absolute u-pos-tl u-flex u-align-items-c u-justify-content-c">
            <span class="c-Cursor-click-label u-relative t-text--sm u-color--black">click</span>
            <div class="c-Cursor-click-icon u-absolute u-pos-tl u-fit u-flex u-align-items-c u-justify-content-c">
                <img class="c-Cursor-click-icon-img u-block u-force-3d" :src="`${publicPath}static/images/icon-cursor.png`" alt="" width="21" height="23">
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import { pointermove } from '@/utilities/pointer';
    import getPrefix from '@/utilities/getPrefix';

    export default {
        name: 'MyCursor',

        data() {
            return {
                publicPath: process.env.BASE_URL,
                activeClass: '',
                value: '',
                theme: '',
            };
        },

        computed: {
            ...mapState('window', [
                'isTouch'
            ])
        },

        created() {
            this.activeClasses = [];

            this.x = 0;
            this.targetX = 0;

            this.y = 0;
            this.targetY = 0;

            this._onMouseMove = this.onMouseMove.bind(this);
        },

        mounted() {
            window.addEventListener(pointermove, this._onMouseMove, false);

            this.$eventHub.$on('update', this.update);
            this.$eventHub.$on('cursor:enter', this.onEnter);
            this.$eventHub.$on('cursor:leave', this.onLeave);
            this.$eventHub.$on('cursor:reset', this.onReset);
        },

        beforeDestroy() {
            window.removeEventListener(pointermove, this._onMouseMove);

            this.$eventHub.$off('update', this.update);
            this.$eventHub.$off('cursor:enter', this.onEnter);
            this.$eventHub.$off('cursor:leave', this.onLeave);
            this.$eventHub.$off('cursor:reset', this.onReset);
        },

        methods: {
            onEnter(name, value, theme) {
                this.activeClasses.push('is-cursor-'+name);
                this.activeClass = this.activeClasses.length ? this.activeClasses[this.activeClasses.length-1] : '';

                if (value) {
                    this.value = value;
                }

                if (theme) {
                    this.theme = theme;
                }
            },

            onLeave(name) {
                this.activeClasses = this.activeClasses.filter(item => item !== 'is-cursor-'+name);
                this.activeClass = this.activeClasses.length ? this.activeClasses[this.activeClasses.length-1] : '';
            },

            onMouseMove(event) {
                if(this.isTouch) return;

                this.pointerEvent = this.isTouch && event.type != 'mousemove' ? (event.touches[0] || event.changedTouches[0]) : event;

                this.targetX = this.pointerEvent.clientX;
                this.targetY = this.pointerEvent.clientY;
            },

            update() {
                if(this.isTouch) return;

                this.x += (this.targetX - this.x) * 0.1;
                this.x = Math.round(this.x*100)/100;
                this.y += (this.targetY - this.y) * 0.1;
                this.y = Math.round(this.y*100)/100;

                // if (this.activeClass.length === 0) return;

                this.$el.style[getPrefix('transform')] = `translate3d(${this.x}px, ${this.y}px, 0px)`;
            },

            onReset() {
                this.activeClasses = [];
                this.activeClass = '';
            },
        }
    }
</script>

<style lang="stylus">
    .c-Cursor {
        transform translateZ(0)
        will-change: transform;
        z-index 9999

        .is-touch & {
            display none
        }

        &-scroll {
            width 42px
            height 42px

            border-radius 50%
            box-shadow: 0px 15px 35px -12px rgba(0, 0, 0, 0.25);

            opacity 0
            visibility hidden
            transform scale(0.6) translateZ(0)
            will-change opacity, visibility, transform
            transition transform 0.4s $ease-out-quart, opacity 0.4s $ease-out-quart, visibility 0.4s $ease-out-quart

            &:before {
                content ""
                position absolute
                top 0
                left 0
                width 100%
                height 100%

                background $colors['white'];
                opacity 0.97
                border-radius 50%

                opacity 0
                transform translateZ(0)
                transition opacity 0.4s $ease-out-quart

                .is-cursor-scroll-dark & {
                    opacity 1
                }
            }

            &:after {
                content ""
                position absolute
                top 0
                left 0
                width 100%
                height 100%

                background $colors['white'];
                border-radius 50%

                opacity 0
                transform translateZ(0)
                transition opacity 0.4s $ease-out-quart

                .is-cursor-scroll & {
                    opacity 1
                }
            }

            .is-cursor-scroll &, .is-cursor-scroll-dark & {
                opacity 1
                visibility visible
                transform translateZ(0)
            }

            img {
                position relative
                z-index 1
            }
        }

        &-view {
            width 72px
            height 72px

            border-radius 50%

            opacity 0
            visibility hidden
            transform scale(0.6) translateZ(0)
            will-change opacity, visibility, transform
            transition transform 0.4s $ease-out-quart, opacity 0.4s $ease-out-quart, visibility 0.4s $ease-out-quart

            .is-cursor-view & {
                opacity 1
                visibility visible
                transform translateZ(0)
            }
        }

        &-download {
            width 72px
            height 72px

            border-radius 50%

            opacity 0
            visibility hidden
            transform scale(0.6) translateZ(0)
            will-change opacity, visibility, transform
            transition transform 0.4s $ease-out-quart, opacity 0.4s $ease-out-quart, visibility 0.4s $ease-out-quart

            img {
                position relative
                z-index 1
            }

            .is-cursor-download & {
                opacity 1
                visibility visible
                transform translateZ(0)
            }
        }

        &-play {
            width 72px
            height 72px

            border-radius 50%

            opacity 0
            visibility hidden
            transform scale(0.6) translateZ(0)
            will-change opacity, visibility, transform
            transition transform 0.4s $ease-out-quart, opacity 0.4s $ease-out-quart, visibility 0.4s $ease-out-quart

            img {
                position relative
                z-index 1
            }

            .is-cursor-play & {
                opacity 1
                visibility visible
                transform translateZ(0)
            }
        }

        &-graph {
            width 42px
            height 42px

            border-radius 50%
            box-shadow: 0px 15px 35px -12px rgba(0, 0, 0, 0.4);

            opacity 0
            visibility hidden
            transform scale(0.6) translateZ(0)
            will-change opacity, visibility, transform
            transition transform 0.4s $ease-out-quart, opacity 0.4s $ease-out-quart, visibility 0.4s $ease-out-quart

            .is-cursor-graph & {
                opacity 1
                visibility visible
                transform translateZ(0)
            }
        }

        &-drag {
            width 102px
            height 102px

            opacity 0
            visibility hidden
            transform scale(1) translateZ(0)
            will-change opacity, visibility, transform
            transition transform 0.4s $ease-out-quart, opacity 0.4s $ease-out-quart, visibility 0.4s $ease-out-quart

            &.is-touch {
                display flex
                top 50%
                left 50%

                margin-top -51px
                margin-left -51px

                pointer-events none
            }

            @media $breakpoints.sm {
                top 50%
                left 50%

                margin-top -51px
                margin-left -51px

                pointer-events none
            }

            .is-cursor-drag & {
                opacity 1
                visibility visible
                transform translateZ(0)
            }

            &:before {
                content ""
                position absolute
                top 0
                left 0
                width 100%
                height 100%

                box-shadow: 0px 15px 35px -12px rgba(0, 0, 0, 0.25);
                border-radius 50%
                background $colors['white'];
                opacity 0.97

                transform scale(1) translateZ(0)

                animation scaleCursor 2.3s infinite
                animation-play-state: paused;

                .is-cursor-drag & {
                    animation-play-state: running;
                }
            }

            &-label {
                opacity 1
                transform translateZ(0)

                animation labelCursor 2.3s infinite
                animation-play-state: paused;

                .is-cursor-drag & {
                    animation-play-state: running;
                }
            }

            &-icon {
                top 15px
                left 15px

                width 72px
                height 72px

                opacity 0
                transform scale(0.5) translateZ(0)

                animation scaleOpacityCursor 2.3s infinite
                animation-play-state: paused;

                .is-cursor-drag & {
                    animation-play-state: running;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;

                    margin-left -3px
                    margin-top -12px

                    width 6px
                    height 24px
                    background $colors['black'];

                    transform translateX(0) translateZ(0)

                    animation dragCursor 2.3s infinite
                    animation-play-state: paused;

                    .is-cursor-drag & {
                        animation-play-state: running;
                    }
                }

                &-arrow {
                    margin-right 4px
                    position relative
                    display block
                    width 20px
                    height 1px

                    &-line {
                        display block
                        position absolute
                        top 0
                        left 0

                        width 100%
                        height 100%

                        background-color: $colors['black'];
                        transform-origin right

                        transform scaleX(1) translateZ(0)

                        animation arrowCursor 2.3s infinite
                        animation-play-state: paused;

                        .is-cursor-drag & {
                            animation-play-state: running;
                        }
                    }

                    &:before, &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;

                        height: 1px
                        width 6px

                        background-color: $colors['black'];
                    }

                    &:before {
                        transform-origin: top right;
                        transform: rotate(40deg);
                    }

                    &:after {
                        transform-origin: bottom right;
                        transform: rotate(-40deg);
                    }

                    &:first-child {
                        margin-left 4px
                        margin-right 0

                        .c-Cursor-drag-icon-arrow-line {
                            transform-origin left

                            animation arrow2Cursor 2.3s infinite
                            animation-play-state: paused;

                            .is-cursor-drag & {
                                animation-play-state: running;
                            }
                        }

                        &:before, &:after {
                            left 0
                        }

                        &:before {
                            transform-origin: top left;
                            transform: rotate(40deg);
                        }

                        &:after {
                            transform-origin: bottom left;
                            transform: rotate(-40deg);
                        }
                    }
                }
            }

        }

        &-spin {
            width 102px
            height 102px

            opacity 0
            visibility hidden
            transform scale(0.6) translateZ(0)
            will-change opacity, visibility, transform
            transition transform 0.4s $ease-out-quart, opacity 0.4s $ease-out-quart, visibility 0.4s $ease-out-quart

            &.is-touch {
                display flex
                z-index 1
                top 50%
                left 50%

                margin-top -51px
                margin-left -51px

                pointer-events none
            }

            @media $breakpoints.sm {
                z-index 1
                top 50%
                left 50%

                margin-top -51px
                margin-left -51px

                pointer-events none
            }

            .is-cursor-spin & {
                opacity 1
                visibility visible
                transform translateZ(0)
            }

            &:before {
                content ""
                position absolute
                top 0
                left 0
                width 100%
                height 100%

                box-shadow: 0px 15px 35px -12px rgba(0, 0, 0, 0.25);
                border-radius 50%
                background $colors['white'];
                opacity 0.97

                transform scale(1) translateZ(0)

                animation scaleCursor 2.3s infinite
                animation-play-state: paused;

                .is-cursor-spin & {
                    animation-play-state: running;
                }
            }

            &-label {
                opacity 1
                transform translateZ(0)

                animation labelCursor 2.3s infinite
                animation-play-state: paused;

                .is-cursor-spin & {
                    animation-play-state: running;
                }
            }

            &-icon {
                overflow hidden

                opacity 0
                transform scale(1) translateZ(0)
                will-change transform

                animation scaleOpacityCursor 2.3s infinite
                animation-play-state: paused;

                .is-cursor-spin & {
                    animation-play-state: running;
                }

                &:before {
                    content ""
                    position absolute
                    top 50%
                    left 50%

                    width 20px
                    height 20px

                    margin-top -10px
                    margin-left -10px

                    background $colors['black'];
                    border-radius 50%
                }

                &-img {
                    display block

                    transform rotate(0deg) translateZ(0)
                    will-change transform

                    animation rotateCursor 2.3s infinite
                    animation-play-state: paused;

                    .is-cursor-spin & {
                        animation-play-state: running;
                    }
                }
            }

        }

        &-click {
            width 102px
            height 102px

            opacity 0
            visibility hidden
            transform scale(0.6) translateZ(0)
            will-change opacity, visibility, transform
            transition transform 0.4s $ease-out-quart, opacity 0.4s $ease-out-quart, visibility 0.4s $ease-out-quart

            @media $breakpoints.sm {
                top 50%
                left 50%

                margin-top -51px
                margin-left -51px

                pointer-events none
            }

            .is-cursor-click & {
                opacity 1
                visibility visible
                transform translateZ(0)
            }

            &:before {
                content ""
                position absolute
                top 0
                left 0
                width 100%
                height 100%

                box-shadow: 0px 15px 35px -12px rgba(0, 0, 0, 0.25);
                border-radius 50%
                background $colors['white'];
                opacity 0.97

                transform scale(1) translateZ(0)

                animation scaleCursor 2.3s infinite
                animation-play-state: paused;

                .is-cursor-click & {
                    animation-play-state: running;
                }
            }

            &-label {
                opacity 1
                transform translateZ(0)

                animation labelCursor 2.3s infinite
                animation-play-state: paused;

                .is-cursor-click & {
                    animation-play-state: running;
                }
            }

            &-icon {
                overflow hidden

                border-radius 50%

                opacity 0
                transform scale(1) translateZ(0)
                will-change transform

                animation scaleOpacityCursor 2.3s infinite
                animation-play-state: paused;

                .is-cursor-click & {
                    animation-play-state: running;
                }

                &:before {
                    content ""
                    position absolute
                    top 50%
                    left 50%

                    width 16px
                    height 16px

                    margin-top -8px
                    margin-left -8px

                    background $colors['black'];
                    border-radius 50%

                    transform scale(1) translateZ(0)
                    will-change transform

                    animation scaleClick 2.3s infinite
                    animation-play-state: paused;

                    .is-cursor-click & {
                        animation-play-state: running;
                    }
                }

                &:after {
                    content ""
                    position absolute
                    top 50%
                    left 50%

                    width 32px
                    height 32px

                    margin-top -16px
                    margin-left -16px

                    border 1px solid $colors['black'];
                    border-radius 50%
                }

                &-img {
                    display block

                    margin-left 20px
                    margin-top 20px

                    transform translate(40px, 40px) translateZ(0)
                    will-change transform

                    animation clickCursor 2.3s infinite
                    animation-play-state: paused;

                    .is-cursor-click & {
                        animation-play-state: running;
                    }
                }
            }

        }

    }

    @keyframes labelCursor {
        0% {
            opacity 1
            transform translateY(0) translateZ(0)
        }
        20% {
            opacity 1
            transform translateY(0) translateZ(0)
        }
        30% {
            opacity 0
            transform translateY(-15px) translateZ(0)
        }
        90% {
            opacity 0
            transform translateY(10px) translateZ(0)
        }
        100% {
            opacity 1
            transform translateY(0) translateZ(0)
        }
    }

    @keyframes scaleCursor {
        0% {
            transform scale(0.7) translateZ(0)
        }
        20% {
            transform scale(0.7) translateZ(0)
        }
        40% {
            transform scale(1) translateZ(0)
        }
        80% {
            transform scale(1) translateZ(0)
        }
        100% {
            transform scale(0.7) translateZ(0)
        }
    }

    @keyframes scaleOpacityCursor {
        0% {
            opacity 0
            transform scale(0.5) translateZ(0)
        }
        20% {
            opacity 0
            transform scale(0.5) translateZ(0)
        }
        40% {
            opacity 1
            transform scale(1) translateZ(0)
        }
        80% {
            opacity 1
            transform scale(1) translateZ(0)
        }
        100% {
            opacity 0
            transform scale(0.5) translateZ(0)
        }
    }

    @keyframes dragCursor {
        0% {
            transform translateX(0) translateZ(0)
        }
        35% {
            transform translateX(0) translateZ(0)
        }
        50% {
            transform translateX(10px) translateZ(0)
        }
        65% {
            transform translateX(-10px) translateZ(0)
        }
        75% {
            transform translateX(0) translateZ(0)
        }
        100% {
            transform translateX(0) translateZ(0)
        }
    }

    @keyframes arrowCursor {
        0% {
            transform scaleX(1) translateZ(0)
        }
        35% {
            transform scaleX(1) translateZ(0)
        }
        50% {
            transform scaleX(0.5) translateZ(0)
        }
        65% {
            transform scaleX(1.5) translateZ(0)
        }
        75% {
            transform scaleX(1) translateZ(0)
        }
        100% {
            transform scaleX(1) translateZ(0)
        }
    }

    @keyframes arrow2Cursor {
        0% {
            transform scaleX(1) translateZ(0)
        }
        35% {
            transform scaleX(1) translateZ(0)
        }
        50% {
            transform scaleX(1.5) translateZ(0)
        }
        65% {
            transform scaleX(0.5) translateZ(0)
        }
        75% {
            transform scaleX(1) translateZ(0)
        }
        100% {
            transform scaleX(1) translateZ(0)
        }
    }

    @keyframes rotateCursor {
        0% {
            transform rotate(0deg) translateZ(0)
        }
        30% {
            transform rotate(0deg) translateZ(0)
        }
        55% {
            transform rotate(180deg) translateZ(0)
        }
        100% {
            transform rotate(720deg) translateZ(0)
        }
    }

    @keyframes scaleClick {
        0% {
            transform scale(0) translateZ(0)
        }
        55% {
            transform scale(0) translateZ(0)
        }
        70% {
            transform scale(1) translateZ(0)
        }
        100% {
            transform scale(1) translateZ(0)
        }
    }

    @keyframes clickCursor {
        0% {
            transform translate(40px, 40px) translateZ(0)
        }
        30% {
            transform translate(40px, 40px) translateZ(0)
        }
        50% {
            transform translateZ(0)
        }
        70% {
            transform translateZ(0)
        }
        80% {
            transform translateZ(0)
        }
        100% {
            transform translateZ(0)
        }
    }
</style>