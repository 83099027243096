<template>
    <div v-bind:class="{ 'is-panel-active': active }" class="c-DownloadPanel u-fixed u-pos-tl u-fit-w u-color--black u-viewport-fit-h" v-bind:style="{ height: height + 'px' }">
        <virtual-scroll ref="virtualScroll" v-bind:active="active" v-bind:class="{ 'c-DownloadPanel-blur': active, 'is-grey': contentOverlayOpen && !isFF, 'is-ff': isFF, 'is-hidden': contentOverlayOpen && isFF }" class="c-Container  u-relative u-fit u-overflow-h">
            <div  class="u-relative u-w5of16 u-offset-l-w6of16 | u-w6of16@lg | u-w6of8@sm u-offset-l-w1of8@sm" v-bind:style="{ padding: offset+'px 0' }">
                <span class="c-DownloadPanel-labels-item t-text--sm u-hide u-marg-b-lg | u-w5of8@sm u-block@sm">{{title}}</span>

                <div v-for="(item, i ) in content" v-bind:key="i" class="js-item c-DownloadPanel-item">
                    <a v-if="type !== 'overlay'" :href="item.url" target="_blank" rel="noopener noreferrer" class="c-DownloadPanel-link t-link u-block">
                        <cursor-trigger v-bind:name="item.cursor" class="u-flex u-align-items-c">
                            <span class="t-text--sm u-uppercase u-font-bold">{{ item.label }}</span>
                            <svg v-if="displayIcon" class="u-marg-l-sm" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11" width="18" height="18">
                                <path fill="#0c1c2a" d="M7.8 4.1L5.9 6V0h-.8L5 6.1 3.2 4.2l-.6.6 2.8 2.9 3-3z"/>
                                <path d="M8.4 1.8c.2.1.3.2.4.3 1.8 1.8 1.8 4.8 0 6.6s-4.8 1.8-6.6 0S.4 4 2.2 2.1c.1-.1.2-.1.3-.2l-.6-.6c-.1.1-.2.1-.3.2-2.1 2.2-2.1 5.7 0 7.8s5.6 2.2 7.8 0 2.1-5.7 0-7.8c-.1-.1-.3-.2-.4-.3l-.6.6z" opacity=".5" fill="#0c1c2a"/>
                            </svg>
                        </cursor-trigger>
                    </a>

                    <button v-if="type === 'overlay'" :href="item.url" v-on:click="onOpen(item)" type="button" v-bind:active="active" class="c-DownloadPanel-link t-btn u-block">
                        <cursor-trigger v-bind:name="item.cursor" v-bind:class="{ 'c-DownloadPanel-blur': active, 'is-blurred': contentOverlayOpen && !isFF }" class="u-flex u-align-items-c">
                            <span class="t-h2">{{ item.label }}</span>
                            <svg v-if="displayIcon" class="u-marg-l-sm" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11" width="18" height="18">
                                <path fill="#0c1c2a" d="M7.8 4.1L5.9 6V0h-.8L5 6.1 3.2 4.2l-.6.6 2.8 2.9 3-3z"/>
                                <path d="M8.4 1.8c.2.1.3.2.4.3 1.8 1.8 1.8 4.8 0 6.6s-4.8 1.8-6.6 0S.4 4 2.2 2.1c.1-.1.2-.1.3-.2l-.6-.6c-.1.1-.2.1-.3.2-2.1 2.2-2.1 5.7 0 7.8s5.6 2.2 7.8 0 2.1-5.7 0-7.8c-.1-.1-.3-.2-.4-.3l-.6.6z" opacity=".5" fill="#0c1c2a"/>
                            </svg>
                        </cursor-trigger>
                        <div class="u-hide u-align-items-c u-marg-t-xs | u-flex@sm">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.4 52.9" width="25" height="25">
                                <path d="M52 26.9c0 12.7-10.3 23-23 23S6 39.6 6 26.9s10.3-23 23-23 23 10.3 23 23zm-42.9 0c0 11 8.9 19.9 19.9 19.9s19.9-8.9 19.9-19.9S40 6.9 29 6.9s-19.9 9-19.9 20z" opacity=".15" fill="#0c1c2a" />
                                <path d="M24.8 17.7l12.3 9.2-12.3 9.2z" fill="#0c1c2a" />
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
        </virtual-scroll>

        <div v-bind:active="active" class="c-DownloadPanel-labels c-Container u-absolute u-fit-w u-pointer-none | u-hide@sm" v-bind:style="{ padding: offset+'px 0' }">
            <div class="u-relative u-offset-l-w2of16 u-w2of16 | u-w3of16@lg">
                <span v-bind:class="{ 'c-DownloadPanel-blur': active, 'is-blurred': contentOverlayOpen && !isFF }" class="c-DownloadPanel-labels-item t-text--sm">{{title}}</span>
            </div>
        </div>

        <div v-bind:active="active" class="c-DownloadPanel-scroll c-Container u-absolute u-pos-bl u-fit-w u-pointer-none | u-hide@sm">
            <cursor-trigger v-bind:name="'scroll'" class="u-inline-block u-offset-l-w2of16 u-pointer-auto">
                <button v-on:click="onScroll" type="button" v-bind:class="{ 'c-DownloadPanel-blur': active, 'is-blurred': contentOverlayOpen && !isFF }" class="c-ScrollBtn c-ScrollBtn--black t-btn t-text--xxs u-font-bold">SCROLL</button>
            </cursor-trigger>
        </div>

        <button v-on:click="onCloseDownloadPanel" type="button" v-bind:active="active" v-bind:class="{ 'c-DownloadPanel-blur': active, 'is-blurred': contentOverlayOpen && !isFF }" class="c-DownloadPanel-btn u-block t-btn u-flex u-align-items-c u-justify-content-c">
            <div class="u-relative">
                <div class="c-DownloadPanel-btn-icon"></div>
            </div>
        </button>

        <content-overlay v-bind:number="active ? 999 : -1" v-bind:vertical="true">
            <div class="u-fit u-flex u-align-items-c" v-bind:style="`height: ${height}px;`">
                <div class="u-w10of16 u-offset-l-w3of16 u-flex u-align-item-c | u-w12of16@md u-offset-l-w2of16@md | u-w16of16@sm u-offset-l-w0of16@sm">
                    <div class="c-DownloadPanel-iframe-wrap u-overflow-h u-relative u-fit">
                        <iframe
                            v-if="currentVideo.youtubeId"
                            v-bind:src="`https://www.youtube.com/embed/${currentVideo.youtubeId}?autoplay=${autoPlay}`"
                            v-bind:class="{ 'is-visible': autoPlay }"
                            class="c-DownloadPanel-iframe u-force-3d u-fit u-absolute u-pos-tl"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        />

                        <div v-on:click="autoPlay = true" v-bind:class="{ 'is-hidden': autoPlay, 'u-pointer-none': !contentOverlayOpen }" class="c-DownloadPanel-poster u-fit u-absolute u-pos-tl u-flex u-align-items-c u-justify-content-c">
                            <img v-bind:src="currentVideo.poster" class="c-DownloadPanel-poster-img u-fit u-object-fit-cover u-absolute u-pos-tl" />
                            <svg class="c-DownloadPanel-poster-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
                                <circle cx="36" cy="36" r="36" fill="#0060b4" />
                                <path d="M32 29.5c0-.5.4-.7.8-.4l8.4 6.3c.4.3.4.9 0 1.2l-8.4 6.3c-.4.3-.8.1-.8-.4v-13z" fill="#fff" />
                            </svg>
                        </div>
                    </div>
                </div>

                <button v-on:click="onClose" type="button" class="c-DownloadPanel-btn u-block t-btn u-flex u-align-items-c u-justify-content-c">
                    <div class="u-relative">
                        <div class="c-DownloadPanel-btn-icon"></div>
                    </div>
                </button>
            </div>
        </content-overlay>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';

    import { disableScroll, enableScroll } from '@/utilities/disableScroll';

    import VirtualScroll from '@/components/VirtualScroll';
    import CursorTrigger from '@/components/CursorTrigger';
    import ContentOverlay from '@/components/ContentOverlay';

    export default {
        name: 'DownloadPanel',

        components: {
            VirtualScroll,
            CursorTrigger,
            ContentOverlay,
        },

        props: {
            active: { type: Boolean, default: false },
            type: { type: String, default: '' },
            title: { type: String, default: '' },
            content: { type: Array, default: () => { return []; } },
            displayIcon: { type: Boolean, default: true },
        },

        data() {
            return {
                currentItem: 0,
                offset: 0,
                autoPlay: false,
            };
        },

        computed: {
            ...mapState('window', [
                'height',
                'isFF',
            ]),

            ...mapGetters('window', [
                'halfHeight',
                'isMobile',
            ]),

            ...mapState('main', [
                'contentOverlayOpen',
                'currentVideo',
            ]),
        },

        watch: {
            'active': 'onActiveChange',
            // 'currentItem': 'onCurrentItemChange',
        },

        created() {
            this._onKeyUp = this.onKeyUp.bind(this);
        },

        mounted() {
            this.$nextTick(() => {
                this.$items = Array.from(this.$el.querySelectorAll('.js-item'));

                this.$eventHub.$on('update', this.update);
                this.$eventHub.$on('resize', this.resize);

                window.addEventListener('keyup', this._onKeyUp, false);

                this.resize();
            });
        },

        beforeDestroy() {
            this.$eventHub.$off('update', this.update);
            this.$eventHub.$off('resize', this.resize);

            window.removeEventListener('keyup', this._onKeyUp, false);
        },

        methods: {
            ...mapActions('main', [
                'toggleDownloadPanelOpen',
                'setActiveContentOverlay',
                'toggleContentOverlayOpen',
                'setCurrentVideo',
            ]),

            resize() {
                if (this.isMobile) {
                    this.offset = 110;
                    return;
                }

                this.items = [];

                this.$items.forEach((el, i) => {
                    let offset = this.items[i-1] ? this.items[i-1].offset + this.items[i-1].height : 0;
                    this.items.push({ height: el.offsetHeight, offset: offset })
                });

                this.offset = (this.height - this.items[0].height) / 2;

                this.$nextTick(() => {
                    this.$refs.virtualScroll.resize();
                });
            },

            update() {
                if (!this.active || this.isMobile) return;

                this.items.forEach((el, i) => {
                    this.nextOffset = this.items[i+1] ? this.items[i+1].offset : 99999999;
                    if(this.$refs.virtualScroll.y > (this.items[i].offset - this.halfHeight + this.offset ) && this.$refs.virtualScroll.y < (this.nextOffset - this.halfHeight + this.offset )) {
                        this.currentItem = i;
                    }
                });
            },

            onActiveChange() {
                if (this.active) {
                    disableScroll();
                } else {
                    enableScroll();
                }
            },

            onScroll() {
                let index = this.currentItem + 1;

                if (index < this.$items.length && index >= 0) {
                    let target =  this.items[index].offset - this.halfHeight + this.offset + this.items[index].height/2;
                    this.currentItem = index;
                    this.$refs.virtualScroll.goToScroll(0, target);
                }
            },

            // onCurrentItemChange() { // improve onWheel
            //     clearTimeout(this.timer);

            //     this.timer = setTimeout(() => {
            //         let target =  this.items[this.currentItem].offset - this.halfHeight + this.offset + this.items[this.currentItem].height/2;
            //         this.$refs.virtualScroll.goToScroll(0, target);
            //     }, 500);
            // },

            onKeyUp(event) {
                if (!this.active) return;

                let index = 0;

                if (event.keyCode === 40 || event.keyCode === 39) {
                    index = this.currentItem + 1;
                } else if (event.keyCode === 38 || event.keyCode === 37) {
                    index = this.currentItem - 1;
                } else {
                    return;
                }

                if (index < this.$items.length && index >= 0) {
                    let target = this.items[index].offset - this.halfHeight + this.offset + this.items[index].height/2;
                    this.currentItem = index;
                    this.$refs.virtualScroll.goToScroll(0, target);
                }
            },

            onCloseDownloadPanel() {
                if (!this.active) return;

                this.toggleDownloadPanelOpen(false);
                this.$eventHub.$emit('webgl:play');
            },

            onOpen(video) {
                if (!this.active) return;

                this.setCurrentVideo(video);
                this.setActiveContentOverlay(999);
                this.toggleContentOverlayOpen(true);
            },

            onClose() {
                if (!this.active) return;

                this.toggleContentOverlayOpen(false);

                setTimeout(() => {
                    this.setActiveContentOverlay(-1);
                }, 800);

                setTimeout(() => {
                    this.setCurrentVideo('');
                    this.autoPlay = false;
                }, 1500);
            }
        }
    }
</script>


<style lang="stylus">
    .c-DownloadPanel {
        z-index 1002

        visibility hidden
        transform translateZ(0)

        will-change visibility

        transition visibility 0.8s $ease-out-quart

        &-blur {
            transition filter 0.5s $ease-out-quart, background 0.5s $ease-out-quart
            filter blur(0)
            // transform translate3d(0, 0, 0)
            opacity 1

            &.is-ff {
                transition background 0.5s $ease-out-quart 0.1s, opacity 0.5s $ease-out-quart 0.1s
            }


            &.is-blurred {
                filter blur(20px)
                // background #f3f7fa
                opacity 1
                transition filter 0.5s $ease-out-quart 0.3s, background 0.5s $ease-out-quart

                @media $breakpoints.sm {
                    filter unset
                }
            }

            &.is-grey {
                background #f3f7fa
            }

            &.is-hidden {
                background #cacaca
                opacity 0.1
            }
        }

        &.is-panel-active {
            opacity 1
            visibility visible

            &:before {
                transform skewY(0deg) scaleY(1)  translateZ(0)
            }
        }

        &:before {
            content ""
            position absolute
            top 0
            left 0
            width 100%
            height 100%
            background $colors['white'];

            transform-origin bottom right
            transform skewY(-7deg) scaleY(0)  translateZ(0)
            transition transform 0.8s $ease-in-out-quart
        }

        &-item {
            padding 55px 0
            opacity 0
            transform translateY(80px) translateZ(0)
            will-change transform, opacity
            transition opacity 0.4s $ease-in-quart, transform 0s linear 0.4s

            .is-panel-active & {
                opacity 1
                transform translateZ(0)
            }

            for n in 0..10 {
                &:nth-child({n}) {
                    .is-panel-active & {
                        transition transform 0.8s $ease-out-quart (0.3s + n * 0.1s), opacity 0.8s $ease-out-quart (0.3s + n * 0.1s)
                    }
                }
            }

            @media $breakpoints.sm {
                padding 40px 0
            }

            &:first-of-type {
                padding-top 0
            }
        }

        &.is-not-video {
            .c-DownloadPanel-item {
                padding 20px 0

                &:first-of-type {
                    padding-top 0
                }
            }
        }

        &-labels {
            left 0
            top 50%
            margin-top 7px
            height 100%

            // margin-top -10px
            // transform translateY(-50%);

            opacity 0
            transform translateY(-50%) translateZ(0)
            will-change transform, opacity
            transition opacity 0.2s $ease-in-quart, transform 0s linear 0.2s

            @media $breakpoints.lg {
                margin-top 0
            }

            .is-panel-active & {
                opacity 1
                transform transform translateY(-50%) translateZ(0)
                transition transform 0.8s $ease-out-quart 0.4s, opacity 0.8s $ease-out-quart 0.4s
            }
        }

        &-scroll {
            margin-bottom 90px

            opacity 0
            transform translateY(80px) translateZ(0)
            will-change transform, opacity
            transition opacity 0.2s $ease-in-quart, transform 0s linear 0.2s

            .is-panel-active & {
                opacity 1
                transform translateZ(0)
                transition transform 0.8s $ease-out-quart 1s, opacity 0.8s $ease-out-quart 1s
            }
        }

        &-link {
            // opacity 0.15
            transform translateZ(0)
            transition color 0.6s $ease-out-quart
            will-change color

            svg path {
                transition fill 0.6s $ease-out-quart
                will-change fill
            }

            &:hover {
                color $colors['blue-light'];

                svg path {
                    fill $colors['blue-light'];
                }
            }

            // &.router-link-exact-active {
            //     opacity 1
            //     color $colors['black'];
            // }
        }

        &-btn {
            position absolute
            top 50%
            right 40px

            margin-top -36px

            width 72px
            height 72px

            border-radius 50%

            opacity 0
            transform translateZ(0)
            will-change transform, opacity
            transition opacity 0.2s $ease-in-quart

            .is-panel-active & {
                opacity 1
                transition opacity 0.8s $ease-out-quart 0.4s
            }

            @media $breakpoints.sm {
                width 54px
                height 54px

                margin-top 0

                top 18px
                right 18px
            }

            &:before {
                content ""
                position absolute
                top 0
                left 0
                width 100%
                height 100%
                border-radius 50%
                border 1px solid $colors['black'];

                opacity 0.15

                transition transform 0.6s $ease-out-quart
                transform scale(1) translateZ(0)
                will-change transform
            }

            &:hover {
                &:before {
                    transform scale(1.2) translateZ(0)
                }
            }

            &-icon {
                position relative
                width 14px
                height 7px

                &:before, &:after {
                    content ""
                    position absolute
                    top 0
                    left 0

                    width 100%
                    height 1px
                    background $colors['black'];

                    transform-origin bottom
                    transform translateZ(0)
                    transition transform 0.6s $ease-out-quart
                    will-change transform
                }

                &:before {
                    transform: translateY(3px) rotate(45deg) translateZ(0)
                }

                &:after {
                    top auto
                    bottom 0
                    transform: translateY(-3px) rotate(-45deg) translateZ(0)
                }
            }
        }

        &-iframe-wrap {
            padding-bottom 56.25%
        }

        &-iframe {
            height 100%
            opacity 0

            .is-visible {
                opacity 1
            }
        }

        &-poster {
            opacity 1
            transition opacity 1s $ease-out-quart 0.5s
            cursor pointer

            &.is-hidden {
                opacity 0
                pointer-events none
            }

            &-img {
                z-index 1
                height 100%
            }

            &-svg {
                z-index 2
                width 72px
                height 72px
                overflow visible !important

                @media $breakpoints.sm {
                    width 54px
                    height 54px
                }

                circle {
                    transform-origin center center
                    transition transform 0.6s $ease-out-quart
                }
            }

            &:hover &-svg circle {
                transform scale(1.2)
            }
        }
    }
</style>