<template>
    <header v-bind:class="{ 'is-active': show, 'is-dark': isDark && !navigationOpen }" class="c-Header c-Container u-fixed u-pos-tl u-fit-w u-overflow-h">
        <div class="u-relative u-fit-w">
            <div class="c-Header-white u-color--white u-relative u-fit-w u-flex u-align-items-c ">
                <router-link to="/" class="t-link u-relative u-w1of16 u-block | u-width-auto@md" aria-label="logo">
                    <svg class="c-Header-logo u-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" width="42" height="42">
                        <path fill="#FFFFFF" d="M57.9 11.2s-5.7-5.3-16.8-4.7c0 0-3.6.5-5.2 2.1-1.7 1.6-4.5 1.9-5.5-.3 0 0-2.3-4.2 6.6-5.2h.5l1.2-.1-2.7-.1c-9.9 0-18.7 4.3-24.8 11.2 0 0-5.3 5.8-4.7 16.9 0 0 .5 3.6 2.1 5.2 1.6 1.7 1.9 4.5-.3 5.5 0 0-4.2 2.3-5.2-6.6v-.5L3 33.4 2.8 36c0 9.9 4.3 18.7 11.2 24.8 0 0 5.8 5.3 16.8 4.7 0 0 3.6-.5 5.2-2.1 1.7-1.7 4.5-1.9 5.5.3 0 0 2.3 4.2-6.6 5.2h-.5l-1.2.1 2.7.1c9.9 0 18.7-4.3 24.8-11.2 0 0 5.3-5.7 4.7-16.8 0 0-.5-3.6-2.1-5.2-1.6-1.7-1.9-4.5.3-5.5 0 0 4.2-2.3 5.2 6.6v.5l.1 1.2.1-2.7c.1-9.8-4.2-18.7-11.1-24.8zM51.6 56c-1.4.6-3 1.1-5.6 1.1 0 0-11.3-1.6-10.7-12.6 0 0 .5-7.7 7.6-11.9 0 0-.5-.4-.5-1.1l-9.7 5.9s.6 11-4.9 16.8c-.8.8-1.8 1.4-2.8 2.1-.7.5-1.5.6-2.1.8-.8.2-1.3.1-2.3.1 0 0-2.7.1-4.2-3.2 0 0-2.6-5.4 2.7-10.9 0 0 3.1-3.9 11-6.5l.1-2.3s-2.8 2.1-4.7 1.7c-1.1-.2-3.7-.4-4.4-4.6 0 0-4.4-1.5-4.7-6 0 0 0-2.7 1.3-3.9 2.2-1.9 1.1 1.2 1.1 1.2-.1 1.9.1 5.2 2.4 5.8 0 0-.1-4.5 2.3-8.2 0 0 4.2-6.2 9-4 0 0 4.5 3.4-1.8 10.4 0 0-2.7 3.7-6.5 4.5 0 0 1.1 4.7 6.5-.8 0 0 1.4-6.5 5.4-4.9 0 0 3.1 2.1-3.5 6.6l-.1 2.4 9-5.6s-.5-4.8.8-8.8c0 0 2.3-5.2 7.3-4.4 0 0 6.1 2.9.3 9.9 0 0-3.3 3.5-5 3.8 0 0 .3 1.3.9 1.8 0 0 7.3-1.5 6.7 1.3 0 0 .3 3.8-7.6 2.4 0 0-5.6 4.7-4.9 9.7 0 0-.6 6.9 6.3 7.9 0 0 7.1.4 6.8-5.8 0 0-.1-5.2-4.4-4.8 0 0-4 1.3-.8 4.8 0 0 1.5 1 .1 2.5 0 0-1.6 1-3.1-.5-.8-.8-1.7-2-1.9-4.6 0 0-.4-6.3 7.2-6.1 0 0 6.9.8 7.1 8.8-.2.3.4 6.6-5.7 9.2z"/>
                        <path fill="#FFFFFF" d="M36 0C16.1 0 0 16.1 0 36s16.1 36 36 36 36-16.1 36-36S55.9 0 36 0zm0 70.7C16.9 70.7 1.3 55.2 1.3 36 1.3 16.9 16.9 1.4 36 1.4S70.6 16.9 70.6 36c0 19.2-15.5 34.7-34.6 34.7z"/>
                        <path fill="#FFFFFF" d="M48.4 23.1c.7-1.2 1.6-2.7.1-3.8 0 0-3.6-.5-3.8 7.7 0 0 2.4-1.9 3.7-3.9zM18.9 50.3s0 1.1.3 1.8 1 .9 1.6 1.4c0 0 3.9 1.8 7.5-6.8 0 0 1.4-3.5 1.3-6.8 0 0-10.5 5.1-10.7 10.4zM30.6 20.5s.2-1-.5-1.3c0 0-4.9.5-5.6 8.7-.1 0 6.2-4.1 6.1-7.4z"/>
                    </svg>
                </router-link>
                <ul class="c-Header-list u-w7of16 u-offset-l-w1of16 t-list u-flex u-align-items-c u-hide@md">
                    <li class="c-Header-list-item">
                        <router-link v-on:click.native="onRouterLinkClick({ name: 'Sections', params: { initIndex: 5 } })" v-bind:to="{ name: 'Sections', params: { initIndex: 5 } }" class="t-link--primary t-link--primary--white t-text--xs u-flex u-align-items-c">
                            <span>Download Reports</span>
                            <div class="c-Header-list-item-icon u-relative">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11" width="11" height="11">
                                    <path fill="#fff" d="M7.8 4.1L5.9 6V0h-.8L5 6.1 3.2 4.2l-.6.6 2.8 2.9 3-3z"/>
                                    <path d="M8.4 1.8c.2.1.3.2.4.3 1.8 1.8 1.8 4.8 0 6.6s-4.8 1.8-6.6 0S.4 4 2.2 2.1c.1-.1.2-.1.3-.2l-.6-.6c-.1.1-.2.1-.3.2-2.1 2.2-2.1 5.7 0 7.8s5.6 2.2 7.8 0 2.1-5.7 0-7.8c-.1-.1-.3-.2-.4-.3l-.6.6z" opacity=".5" fill="#fff"/>
                                </svg>
                            </div>
                        </router-link>
                    </li>
                    <li class="c-Header-list-item">
                        <a href="https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2020-Press_Release.pdf" target="_blank" rel="noopener noreferrer" class="t-link--primary t-link--primary--white t-text--xs u-flex u-align-items-c">
                            <span>Press Release</span>
                            <div class="c-Header-list-item-icon u-relative">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11" width="11" height="11">
                                    <path fill="#fff" d="M7.8 4.1L5.9 6V0h-.8L5 6.1 3.2 4.2l-.6.6 2.8 2.9 3-3z"/>
                                    <path d="M8.4 1.8c.2.1.3.2.4.3 1.8 1.8 1.8 4.8 0 6.6s-4.8 1.8-6.6 0S.4 4 2.2 2.1c.1-.1.2-.1.3-.2l-.6-.6c-.1.1-.2.1-.3.2-2.1 2.2-2.1 5.7 0 7.8s5.6 2.2 7.8 0 2.1-5.7 0-7.8c-.1-.1-.3-.2-.4-.3l-.6.6z" opacity=".5" fill="#fff"/>
                                </svg>
                            </div>
                        </a>
                    </li>
                </ul>
                <div class="u-w6of16 u-offset-l-w1of16 u-flex u-align-items-c u-hide@md">
                    <ul class="c-Header-list t-list u-flex u-align-items-c">
                        <li class="c-Header-list-item">
                            <a href="https://www.ge.com" target="_blank" rel="noopener noreferrer" class="t-link--primary t-link--primary--white t-text--xs u-flex u-align-items-c">
                                <span>GE.com</span>
                                <div class="c-Header-list-item-icon u-relative">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11" width="11" height="11">
                                        <path fill="#fff" d="M7.2 0v.8h2.4l-4 4.1.6.5 4-4v2.5h.8V0z"/>
                                        <path d="M9.7 5.3v.5c0 2.4-2 4.4-4.4 4.4S.9 8.2.9 5.8s2-4.4 4.4-4.4h.4V.6h-.4C2.4.6 0 2.9 0 5.8S2.4 11 5.3 11s5.3-2.3 5.3-5.2v-.5h-.9z" opacity=".5" fill="#fff"/>
                                    </svg>
                                </div>
                            </a>
                        </li>
                        <li v-on:mouseleave="shareActive = false" v-bind:class="{ 'is-share': shareActive }" class="c-Header-list-item u-relative">
                            <button v-on:mouseenter="shareActive = true"  type="button" class="c-Header-share-btn t-btn t-link t-text--xs">Share</button>
                            <ul class="c-Header-share t-list u-flex u-absolute u-pos-tl">
                                <li><a :href="`https://www.facebook.com/sharer/sharer.php?u=${url}`" target="_blank" rel="noopener noreferrer" class="c-Header-share-item u-block t-link t-text--xs u-pad-r-xs">Fb</a></li>
                                <li><a :href="`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=&summary=&source=`" target="_blank" rel="noopener noreferrer" class="c-Header-share-item u-block t-link t-text--xs u-pad-r-xs">In</a></li>
                                <li><a :href="`https://twitter.com/intent/tweet?url=${url}&text=`" target="_blank" rel="noopener noreferrer" class="c-Header-share-item u-block t-link t-text--xs u-pad-r-xs">Tw</a></li>
                                <li><a :href="`mailto:?&subject=Global Innovation Barometer 2020&body=${url}`" target="_blank" rel="noopener noreferrer" class="c-Header-share-item u-block t-link t-text--xs">Email</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>

            </div>
            <div class="c-Header-dark u-absolute u-pos-tl u-fit-w u-flex u-align-items-c ">
                <router-link to="/" class="t-link u-relative u-w1of16 u-block | u-width-auto@md" aria-label="logo">
                    <svg class="c-Header-logo u-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" width="42" height="42">
                        <path fill="#0060b4" d="M57.9 11.2s-5.7-5.3-16.8-4.7c0 0-3.6.5-5.2 2.1-1.7 1.6-4.5 1.9-5.5-.3 0 0-2.3-4.2 6.6-5.2h.5l1.2-.1-2.7-.1c-9.9 0-18.7 4.3-24.8 11.2 0 0-5.3 5.8-4.7 16.9 0 0 .5 3.6 2.1 5.2 1.6 1.7 1.9 4.5-.3 5.5 0 0-4.2 2.3-5.2-6.6v-.5L3 33.4 2.8 36c0 9.9 4.3 18.7 11.2 24.8 0 0 5.8 5.3 16.8 4.7 0 0 3.6-.5 5.2-2.1 1.7-1.7 4.5-1.9 5.5.3 0 0 2.3 4.2-6.6 5.2h-.5l-1.2.1 2.7.1c9.9 0 18.7-4.3 24.8-11.2 0 0 5.3-5.7 4.7-16.8 0 0-.5-3.6-2.1-5.2-1.6-1.7-1.9-4.5.3-5.5 0 0 4.2-2.3 5.2 6.6v.5l.1 1.2.1-2.7c.1-9.8-4.2-18.7-11.1-24.8zM51.6 56c-1.4.6-3 1.1-5.6 1.1 0 0-11.3-1.6-10.7-12.6 0 0 .5-7.7 7.6-11.9 0 0-.5-.4-.5-1.1l-9.7 5.9s.6 11-4.9 16.8c-.8.8-1.8 1.4-2.8 2.1-.7.5-1.5.6-2.1.8-.8.2-1.3.1-2.3.1 0 0-2.7.1-4.2-3.2 0 0-2.6-5.4 2.7-10.9 0 0 3.1-3.9 11-6.5l.1-2.3s-2.8 2.1-4.7 1.7c-1.1-.2-3.7-.4-4.4-4.6 0 0-4.4-1.5-4.7-6 0 0 0-2.7 1.3-3.9 2.2-1.9 1.1 1.2 1.1 1.2-.1 1.9.1 5.2 2.4 5.8 0 0-.1-4.5 2.3-8.2 0 0 4.2-6.2 9-4 0 0 4.5 3.4-1.8 10.4 0 0-2.7 3.7-6.5 4.5 0 0 1.1 4.7 6.5-.8 0 0 1.4-6.5 5.4-4.9 0 0 3.1 2.1-3.5 6.6l-.1 2.4 9-5.6s-.5-4.8.8-8.8c0 0 2.3-5.2 7.3-4.4 0 0 6.1 2.9.3 9.9 0 0-3.3 3.5-5 3.8 0 0 .3 1.3.9 1.8 0 0 7.3-1.5 6.7 1.3 0 0 .3 3.8-7.6 2.4 0 0-5.6 4.7-4.9 9.7 0 0-.6 6.9 6.3 7.9 0 0 7.1.4 6.8-5.8 0 0-.1-5.2-4.4-4.8 0 0-4 1.3-.8 4.8 0 0 1.5 1 .1 2.5 0 0-1.6 1-3.1-.5-.8-.8-1.7-2-1.9-4.6 0 0-.4-6.3 7.2-6.1 0 0 6.9.8 7.1 8.8-.2.3.4 6.6-5.7 9.2z"/>
                        <path fill="#0060b4" d="M36 0C16.1 0 0 16.1 0 36s16.1 36 36 36 36-16.1 36-36S55.9 0 36 0zm0 70.7C16.9 70.7 1.3 55.2 1.3 36 1.3 16.9 16.9 1.4 36 1.4S70.6 16.9 70.6 36c0 19.2-15.5 34.7-34.6 34.7z"/>
                        <path fill="#0060b4" d="M48.4 23.1c.7-1.2 1.6-2.7.1-3.8 0 0-3.6-.5-3.8 7.7 0 0 2.4-1.9 3.7-3.9zM18.9 50.3s0 1.1.3 1.8 1 .9 1.6 1.4c0 0 3.9 1.8 7.5-6.8 0 0 1.4-3.5 1.3-6.8 0 0-10.5 5.1-10.7 10.4zM30.6 20.5s.2-1-.5-1.3c0 0-4.9.5-5.6 8.7-.1 0 6.2-4.1 6.1-7.4z"/>
                    </svg>
                </router-link>
                <ul class="c-Header-list u-w7of16 u-offset-l-w1of16 t-list u-flex u-align-items-c u-hide@md">
                    <li class="c-Header-list-item">
                        <router-link v-bind:to="{ name: 'Sections', params: { initIndex: 5 } }" class="t-link--primary t-text--xs u-flex u-align-items-c">
                            <span>Download Reports</span>
                            <div class="c-Header-list-item-icon u-relative">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11" width="11" height="11">
                                    <path fill="#0c1c2a" d="M7.8 4.1L5.9 6V0h-.8L5 6.1 3.2 4.2l-.6.6 2.8 2.9 3-3z"/>
                                    <path d="M8.4 1.8c.2.1.3.2.4.3 1.8 1.8 1.8 4.8 0 6.6s-4.8 1.8-6.6 0S.4 4 2.2 2.1c.1-.1.2-.1.3-.2l-.6-.6c-.1.1-.2.1-.3.2-2.1 2.2-2.1 5.7 0 7.8s5.6 2.2 7.8 0 2.1-5.7 0-7.8c-.1-.1-.3-.2-.4-.3l-.6.6z" opacity=".5" fill="#0c1c2a"/>
                                </svg>
                            </div>
                        </router-link>
                    </li>
                    <li class="c-Header-list-item">
                        <a href="https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2020-Press_Release.pdf" target="_blank" rel="noopener noreferrer" class="t-link--primary t-text--xs u-flex u-align-items-c">
                            <span>Press Release</span>
                            <div class="c-Header-list-item-icon u-relative">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11" width="11" height="11">
                                    <path fill="#0c1c2a" d="M7.8 4.1L5.9 6V0h-.8L5 6.1 3.2 4.2l-.6.6 2.8 2.9 3-3z"/>
                                    <path d="M8.4 1.8c.2.1.3.2.4.3 1.8 1.8 1.8 4.8 0 6.6s-4.8 1.8-6.6 0S.4 4 2.2 2.1c.1-.1.2-.1.3-.2l-.6-.6c-.1.1-.2.1-.3.2-2.1 2.2-2.1 5.7 0 7.8s5.6 2.2 7.8 0 2.1-5.7 0-7.8c-.1-.1-.3-.2-.4-.3l-.6.6z" opacity=".5" fill="#0c1c2a"/>
                                </svg>
                            </div>
                        </a>
                    </li>
                </ul>
                <div class="u-w6of16 u-offset-l-w1of16 u-flex u-align-items-c u-hide@md">
                    <ul class="c-Header-list t-list u-flex u-align-items-c">
                        <li class="c-Header-list-item">
                            <a href="https://ge.com" target="_blank" rel="noopener noreferrer" class="t-link--primary t-text--xs u-flex u-align-items-c">
                                <span>GE.com</span>
                                <div class="c-Header-list-item-icon u-relative">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11" width="11" height="11">
                                        <path fill="#0c1c2a" d="M7.2 0v.8h2.4l-4 4.1.6.5 4-4v2.5h.8V0z"/>
                                        <path d="M9.7 5.3v.5c0 2.4-2 4.4-4.4 4.4S.9 8.2.9 5.8s2-4.4 4.4-4.4h.4V.6h-.4C2.4.6 0 2.9 0 5.8S2.4 11 5.3 11s5.3-2.3 5.3-5.2v-.5h-.9z" opacity=".5" fill="#0c1c2a"/>
                                    </svg>
                                </div>
                            </a>
                        </li>
                        <li v-on:mouseleave="shareActive = false" v-bind:class="{ 'is-share': shareActive }" class="c-Header-list-item u-relative">
                            <button v-on:mouseenter="shareActive = true"  type="button" class="c-Header-share-btn t-btn t-link t-text--xs">Share</button>
                            <ul class="c-Header-share t-list u-flex u-absolute u-pos-tl">
                                <li><a :href="`https://www.facebook.com/sharer/sharer.php?u=${url}`" target="_blank" rel="noopener noreferrer" class="c-Header-share-item u-block t-link t-text--xs u-pad-r-xs">Fb</a></li>
                                <li><a :href="`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=&summary=&source=`" target="_blank" rel="noopener noreferrer" class="c-Header-share-item u-block t-link t-text--xs u-pad-r-xs">In</a></li>
                                <li><a :href="`https://twitter.com/intent/tweet?url=${url}&text=`" target="_blank" rel="noopener noreferrer" class="c-Header-share-item u-block t-link t-text--xs u-pad-r-xs">Tw</a></li>
                                <li><a :href="`mailto:?&subject=Global Innovation Barometer 2020&body=${url}`" target="_blank" rel="noopener noreferrer" class="c-Header-share-item u-block t-link t-text--xs">Email</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </header>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import { Quart } from 'gsap';

    import getPrefix from '@/utilities/getPrefix';
    import getOffsetTop from '@/utilities/getOffsetTop';

    export default {
        name: 'MyHeader',

        data() {
            return {
                show: false,
                isDark: false,

                index: 0,

                shareActive: false,
                url: window.location.href,
            };
        },

        computed: {
            ...mapState('window', [
                'minHeight',
            ]),

            ...mapState('main', [
                'navigationOpen',
                'currentNav',
            ]),

            ...mapGetters('window', [
                'isMobile',
            ]),
        },

        watch: {
            'index': 'onIndexChange',
            '$route': 'onRouteChange',
        },

        created() {
            this.cuePoints = [];
            this.$switchElements = [];
        },

        mounted() {
            this.$eventHub.$on('update', this.update);
            this.$eventHub.$on('resize', this.resize);

            this.$eventHub.$on('header:show', this.onShow);
            this.$eventHub.$on('header:hide', this.onHide);
            this.$eventHub.$on('header:reset', this.onReset);
        },

        beforeDestroy() {
            this.$eventHub.$off('update', this.update);
            this.$eventHub.$off('resize', this.resize);

            this.$eventHub.$off('header:show', this.onShow);
            this.$eventHub.$off('header:hide', this.onHide);
            this.$eventHub.$off('header:reset', this.onReset);
        },

        methods: {
            ...mapActions('main', [
                'setCurrentNav',
            ]),

            onShow() {
                this.isTransition = false;
                this.show = true;
            },

            onHide() {
                this.show = false;
            },

            resize() {
                this.offsetTop = getOffsetTop(this.$el);
                this.height = this.$el.offsetHeight + this.offsetTop;
                this.halfHeight = this.$el.offsetHeight / 2;

                this.offsetCuePoint = this.halfHeight + this.offsetTop;

                this.cuePoints = [];

                this.$switchElements.forEach((el) => {
                    this.cuePoints.push(getOffsetTop(el));
                });
            },

            update() {
                if (!this.show) return;
                // hide header after minHeight
                // -------------

                if (this.$root.smoothScroll > this.oldScrollTop && this.$root.smoothScroll > this.minHeight) {
                    if (this.direction !== 1) {
                        this.direction = 1;

                        this.currentScroll = this.$root.smoothScroll
                        this.currentY = this.height - this.y;
                    }

                    if (this.$root.smoothScroll <= this.currentScroll + this.height) {
                        this.y = -(this.currentY - ((this.currentScroll + this.height) - this.$root.smoothScroll));
                    } else {
                        this.y = -this.height;
                    }
                }
                else if (this.$root.smoothScroll != this.oldScrollTop && this.$root.smoothScroll < this.minHeight + this.height) {
                    if(this.direction !== -1) {
                        this.direction = -1;

                        this.currentScroll = this.$root.smoothScroll
                        this.currentY = this.height + this.y;
                    }

                    if (this.$root.smoothScroll >= this.currentScroll - this.height && this.y < 0) {
                        this.y = (this.currentScroll - this.height) - this.$root.smoothScroll + this.currentY;
                    } else {
                        this.y = 0;
                    }
                }

                // if (this.navigationOpen) {
                //     this.y = 0;
                // }

                this.$el.style[getPrefix('transform')] = `translate3d(0px, ${this.y}px, 0px)`;

                this.oldScrollTop = this.$root.smoothScroll;


                // Change color header
                // ----------

                if (!this.cuePoints) return;

                this.cuePoints.forEach((offset, i) => {
                    let nextCuePoints = this.cuePoints[i+1] ? this.cuePoints[i+1] : 99999999;

                    if (this.$root.smoothScroll > this.cuePoints[i] - this.offsetCuePoint && this.$root.smoothScroll < nextCuePoints - this.offsetCuePoint ) {
                        this.index = i;
                    }
                });
            },

            onReset() {
                this.index = 0;
                this.isDark = false;
                this.cuePoints = [];
                this.$switchElements = Array.from(document.querySelectorAll('.js-ui-switch'));

                this.y = 0;
            },

            onRouteChange() {
                this.isDark = false;
                this.isTransition = true;
            },

            onRouterLinkClick(to) {
                if (this.$route.name === to.name && to.name === 'Sections' && this.currentNav !== to.params.initIndex + 1) {
                    this.$eventHub.$emit('sections:change', to.params.initIndex);
                    this.$eventHub.$emit('webgl:section', 'reports', 2.4, Quart.easeInOut);
                }
            },

            onIndexChange() {
                if(this.isTransition) return;

                if(this.index === 0) {
                    this.isDark = false;
                } else {
                    this.isDark = !this.isDark;
                }
            },
        }
    }
</script>

<style lang="stylus">
    .c-Header {
        z-index 1000

        margin-top 45px

        opacity 0
        visibility hidden

        transform translateZ(0)
        will-change: transform, opacity, visibility;

        @media $breakpoints.sm {
            margin-top 22px
        }

        &.is-active {
            opacity 1
            visibility visible

            transform translateZ(0)

            transition opacity 0.8s $ease-out-quart, visibility 0.8s $ease-out-quart
        }

        &-white {
            perspective 500px
            backface-visibility hidden
            opacity 1
            pointer-events auto

            transform translateZ(0)
            will-change: opacity, pointer-events;

            transition opacity 0.8s $ease-out-quart

            .is-dark & {
                opacity 0
                pointer-events none
            }
        }

        &-dark {
            perspective 500px
            backface-visibility hidden
            opacity 0
            pointer-events none

            transform translateZ(0)
            will-change: opacity, pointer-events;

            transition opacity 0.8s $ease-out-quart

            .is-dark & {
                opacity 1
                pointer-events auto
            }
        }

        .t-link--primary {
            vertical-align middle
            overflow hidden
        }

        &-logo {
            width 46px
            height 46px
        }

        &-list {
            &--alt {
                margin 0
                margin-left 35px
                padding 0
            }

            &-item {
                margin-right 45px

                &:last-child {
                    margin-right 0
                }

                &--alt {
                    margin-right 20px
                }

                &-icon {
                    margin-left 6px
                }

                svg {
                    display block
                }
            }
        }

        &-share {
            opacity 0
            visibility hidden

            transform translateZ(0)
            will-change opacity, visibility

            .is-share & {
                opacity 1
                visibility visible

                transition opacity 0.8s $ease-out-quart, visibility 0.8s $ease-out-quart
            }

            &-btn {
                opacity 1
                visibility visible
                transform translateZ(0)
                will-change opacity, visibility
                transition opacity 0.8s $ease-out-quart, visibility 0.8s $ease-out-quart


                .is-share & {
                    opacity 0
                    visibility hidden

                    transition none
                }
            }

            &:hover {
                li {
                    opacity 0.3
                }
            }

            li {
                opacity 1
                transform translateZ(0)
                will-change opacity
                transition opacity 0.6s $ease-out-quart

                &:hover {
                    opacity 1
                }
            }
        }
    }
</style>