<template>
    <div class="c-ParallaxObject">
        <slot></slot>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import getOffsetTop from '@/utilities/getOffsetTop';
    import getPrefix from '@/utilities/getPrefix';

    export default {
        name: 'ParallaxObject',

        props: {
            active: { type: Boolean, default: true },
            scale: { type: Number, default: 1 },
            ratio: { type: Number, default: 1 },
            securityCulling: { type: Number, default: 0 },
        },

        computed: {
            ...mapState('window', {
                'isTouch': 'isTouch',
                'winHeight': 'height',
            }),
        },

        created() {
            this.enabled = false;
            this.translation = 0;
            this.currTranslation = 0;
        },

        mounted() {
            this.$nextTick(() => {
                this.$eventHub.$on('resize', this.resize);
                this.$eventHub.$on('update', this.update);

                this.resize();
            });
        },

        beforeDestroy() {
            this.$eventHub.$off('resize', this.resize);
            this.$eventHub.$off('update', this.update);
        },

        methods: {
            resize() {
                if (this.isTouch) {
                    this.$el.style[getPrefix('transform')] = 'none';
                } else {
                    this.height = this.$el.offsetHeight;
                    this.offsetY = getOffsetTop(this.$el);

                    if (this.active) {
                        this.translateY = (this.$root.smoothScroll - this.offsetY) * this.ratio
                        this.$el.style[getPrefix('transform')] = `translate3d(0px, ${-this.translateY}px, 0px) scale(${this.scale})`;
                    }
                }
            },

            update() {
                if (!this.active || this.isTouch) return;

                if (this.ratio < 0) {
                    this.translateY = (this.$root.smoothScroll - this.offsetY) * this.ratio + ((this.winHeight - this.height) * (this.ratio));
                } else {
                    this.translateY = (this.$root.smoothScroll - this.offsetY) * this.ratio + ((this.winHeight - this.height) * (this.ratio/2));
                }
                this.translateY = Math.round(this.translateY*100)/100;

                if ((this.offsetY + this.height) - this.$root.smoothScroll + this.securityCulling < 0
                    || (this.offsetY - this.winHeight) - this.$root.smoothScroll - this.securityCulling > 0) {

                    if(this.enabled) {
                        this.enabled = false;
                        this.$el.style[getPrefix('transform')] = `translate3d(0px, ${-this.translateY}px, 0px) scale(${this.scale})`;
                    }
                } else {
                    if(!this.enabled){
                        this.enabled = true;
                    }
                    this.$el.style[getPrefix('transform')] = `translate3d(0px, ${-this.translateY}px, 0px) scale(${this.scale})`;
                }
            },
        },
    };
</script>

<style lang="stylus">
    .c-ParallaxObject {
        will-change transform
    }
</style>
