import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/sections',
        name: 'Sections',
        props: true,
        // route level code-splitting
        // this generates a separate chunk (Sections.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Sections" */ '../views/Sections.vue')
    },
    // {
    //     path: '/sections/section-one',
    //     name: 'SectionOne',
    //     props: true,
    //     meta: { type: 'section' },
    //     // route level code-splitting
    //     // this generates a separate chunk (SectionTwo.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "SectionTwo" */ '../views/SectionOne.vue')
    // },
    {
        path: '/sections/future-of-innovation',
        name: 'SectionOne',
        props: true,
        meta: { type: 'section' },
        // route level code-splitting
        // this generates a separate chunk (SectionTwo.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "SectionTwo" */ '../views/SectionOne.vue')
    },
    {
        path: '/sections/world-of-innovations-nations',
        name: 'SectionTwo',
        props: true,
        meta: { type: 'section' },
        // route level code-splitting
        // this generates a separate chunk (SectionTwo.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "SectionTwo" */ '../views/SectionTwo.vue')
    },
    {
        path: '/sections/redefinition-of-global-innovation',
        name: 'SectionThree',
        props: true,
        meta: { type: 'section' },
        // route level code-splitting
        // this generates a separate chunk (SectionThree.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "SectionThree" */ '../views/SectionThree.vue')
    },
    {
        path: '/sections/protectionism-continues-to-grow',
        name: 'SectionFour',
        props: true,
        meta: { type: 'section' },
        // route level code-splitting
        // this generates a separate chunk (SectionFour.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "SectionFour" */ '../views/SectionFour.vue')
    },
    {
        path: '/sections/jobs-and-artificial-intelligence',
        name: 'SectionFive',
        props: true,
        meta: { type: 'section' },
        // route level code-splitting
        // this generates a separate chunk (SectionFive.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "SectionFive" */ '../views/SectionFive.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    // scrollBehavior (to, from, savedPosition) {
    //     if ('scrollRestoration' in history) {
    //         history.scrollRestoration = 'manual';
    //     }
    //     let position = { x: 0, y: 0 }
    //     if (savedPosition) {
    //       position = savedPosition
    //     }
    //     return new Promise((resolve) => {
    //       setTimeout(() => {
    //         resolve(position)
    //       }, 5000)
    //     })
    // }
})

export default router
