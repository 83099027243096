<template>
    <div v-bind:class="{ 'is-nav-active': navigationOpen }" class="c-Navigation c-Container u-fixed u-pos-tl u-fit-w u-color--white u-viewport-fit-h" v-bind:style="{ height: height + 'px' }">
        <virtual-scroll ref="virtualScroll" v-bind:active="navigationOpen" class="u-relative u-fit u-overflow-h">
            <div class="u-relative u-w5of16 u-offset-l-w6of16 | u-w6of16@lg | u-w6of8@sm u-offset-l-w1of8@sm" v-bind:style="{ padding: offset+'px 0' }">
                <div data-id="0" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link to="/" v-bind:class="{ 'is-current': currentNav === 0 }" draggable="false" class="c-Navigation-link t-link u-block">
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-text--xxs u-font-bold u-uppercase u-marg-b-xs u-hide | u-block@sm">Home</span>
                            <span class="t-h2">Global Innovation Barometer 2020</span>
                        </cursor-trigger>
                    </router-link>
                </div>

                <!-- section 1 -->

                <div data-id="10" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link v-on:click.native="onRouterLinkClick({ name: 'SectionOne', params: { initIndex: 0 } })" v-bind:to="{ name: 'SectionOne', params: { initIndex: 0 } }" v-bind:class="{ 'is-current': currentNav === 10 }" draggable="false" class="c-Navigation-link t-link u-block">
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-text--xxs u-font-bold u-uppercase u-marg-b-xs u-hide | u-block@sm">Section 01</span>
                            <span class="t-h2">The Future of Innovation</span>
                        </cursor-trigger>
                    </router-link>
                </div>
                <div data-id="11" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link v-on:click.native="onRouterLinkClick({ name: 'SectionOne', params: { initIndex: 1 } })" v-bind:to="{ name: 'SectionOne', params: { initIndex: 1 } }" v-bind:class="{ 'is-current': currentNav === 11 }" draggable="false" class="c-Navigation-link t-link u-block">
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-text--xxs u-font-bold u-uppercase u-marg-b-xs u-hide | u-block@sm">Section 01<br>The Future of Innovation</span>
                            <span class="t-h2">Impacts on innovation</span>
                        </cursor-trigger>
                    </router-link>
                </div>
                <div data-id="12" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link v-on:click.native="onRouterLinkClick({ name: 'SectionOne', params: { initIndex: 2 } })" v-bind:to="{ name: 'SectionOne', params: { initIndex: 2 } }" v-bind:class="{ 'is-current': currentNav === 12 }" draggable="false" class="c-Navigation-link t-link u-block">
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-h2">Need for Speed?</span>
                        </cursor-trigger>
                    </router-link>
                </div>
                <div data-id="13" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link v-on:click.native="onRouterLinkClick({ name: 'SectionOne', params: { initIndex: 3 } })" v-bind:to="{ name: 'SectionOne', params: { initIndex: 3 } }" v-bind:class="{ 'is-current': currentNav === 13 }" draggable="false" class="c-Navigation-link t-link u-block">
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-h2">Covid-19, Innovation & Healthcare</span>
                        </cursor-trigger>
                    </router-link>
                </div>

                <!-- section 2 -->

                <div data-id="20" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link v-on:click.native="onRouterLinkClick({ name: 'SectionTwo', params: { initIndex: 0 } })" v-bind:to="{ name: 'SectionTwo', params: { initIndex: 0 } }" v-bind:class="{ 'is-current': currentNav === 20 }" draggable="false" class="c-Navigation-link t-link u-block">
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-text--xxs u-font-bold u-uppercase u-marg-b-xs u-hide | u-block@sm">Section 02</span>
                            <span class="t-h2">A World of Innovation Nations</span>
                        </cursor-trigger>
                    </router-link>
                </div>
                <div data-id="21" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link v-on:click.native="onRouterLinkClick({ name: 'SectionTwo', params: { initIndex: 1 } })" v-bind:to="{ name: 'SectionTwo', params: { initIndex: 1 } }" v-bind:class="{ 'is-current': currentNav === 21 }" draggable="false" class="c-Navigation-link t-link u-block">
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-text--xxs u-font-bold u-uppercase u-marg-b-xs u-hide | u-block@sm">Section 02<br>A World of Innovation Nations</span>
                            <span class="t-h2">Interconnected across the world</span>
                        </cursor-trigger>
                    </router-link>
                </div>
                <div data-id="22" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link v-on:click.native="onRouterLinkClick({ name: 'SectionTwo', params: { initIndex: 2 } })" v-bind:to="{ name: 'SectionTwo', params: { initIndex: 2 } }" v-bind:class="{ 'is-current': currentNav === 22 }" draggable="false" class="c-Navigation-link t-link u-block">
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-h2">Increasing pride and confidence</span>
                        </cursor-trigger>
                    </router-link>
                </div>
                <div data-id="23" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link v-on:click.native="onRouterLinkClick({ name: 'SectionTwo', params: { initIndex: 3 } })" v-bind:to="{ name: 'SectionTwo', params: { initIndex: 3 } }" v-bind:class="{ 'is-current': currentNav === 23 }" draggable="false" class="c-Navigation-link t-link u-block">
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-h2">A sustainable future</span>
                        </cursor-trigger>
                    </router-link>
                </div>

                <!-- section 3 -->

                <div data-id="30" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link v-on:click.native="onRouterLinkClick({ name: 'SectionThree', params: { initIndex: 0 } })" v-bind:to="{ name: 'SectionThree', params: { initIndex: 0 } }" v-bind:class="{ 'is-current': currentNav === 30 }" draggable="false" class="c-Navigation-link t-link u-block">
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-text--xxs u-font-bold u-uppercase u-marg-b-xs u-hide | u-block@sm">Section 03</span>
                            <span class="t-h2">A redefinition of the global innovation influence map</span>
                        </cursor-trigger>
                    </router-link>
                </div>
                <div data-id="31" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link v-on:click.native="onRouterLinkClick({ name: 'SectionThree', params: { initIndex: 1 } })" v-bind:to="{ name: 'SectionThree', params: { initIndex: 1 } }" v-bind:class="{ 'is-current': currentNav === 31 }" draggable="false" class="c-Navigation-link t-link u-block">
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-text--xxs u-font-bold u-uppercase u-marg-b-xs u-hide | u-block@sm">Section 03<br>A redefinition of the global innovation influence map</span>
                            <span class="t-h2">China closing the gap on the USA</span>
                        </cursor-trigger>
                    </router-link>
                </div>
                <div data-id="32" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link v-on:click.native="onRouterLinkClick({ name: 'SectionThree', params: { initIndex: 2 } })" v-bind:to="{ name: 'SectionThree', params: { initIndex: 2 } }" v-bind:class="{ 'is-current': currentNav === 32 }" draggable="false" class="c-Navigation-link t-link u-block">
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-h2">The battle of the giants</span>
                        </cursor-trigger>
                    </router-link>
                </div>

                <!-- section 4 -->

                <div data-id="40" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link v-on:click.native="onRouterLinkClick({ name: 'SectionFour', params: { initIndex: 0 } })" v-bind:to="{ name: 'SectionFour', params: { initIndex: 0 } }"  v-bind:class="{ 'is-current': currentNav === 40 }" draggable="false" class="c-Navigation-link t-link u-block">
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-text--xxs u-font-bold u-uppercase u-marg-b-xs u-hide | u-block@sm">Section 04</span>
                            <span class="t-h2">Protectionism Continues to grow</span>
                        </cursor-trigger>
                    </router-link>
                </div>
                <div data-id="41" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link v-on:click.native="onRouterLinkClick({ name: 'SectionFour', params: { initIndex: 1 } })" v-bind:to="{ name: 'SectionFour', params: { initIndex: 1 } }"  v-bind:class="{ 'is-current': currentNav === 41 }" draggable="false" class="c-Navigation-link t-link u-block">
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-text--xxs u-font-bold u-uppercase u-marg-b-xs u-hide | u-block@sm">Section 04<br>Protectionism Continues to grow</span>
                            <span class="t-h2">Innovation in a protectionist environment</span>
                        </cursor-trigger>
                    </router-link>
                </div>
                <div data-id="42" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link v-on:click.native="onRouterLinkClick({ name: 'SectionFour', params: { initIndex: 2 } })" v-bind:to="{ name: 'SectionFour', params: { initIndex: 2 } }"  v-bind:class="{ 'is-current': currentNav === 42 }" draggable="false" class="c-Navigation-link t-link u-block">
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-h2">Benefits of protectionism</span>
                        </cursor-trigger>
                    </router-link>
                </div>
                <div data-id="43" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link v-on:click.native="onRouterLinkClick({ name: 'SectionFour', params: { initIndex: 3 } })" v-bind:to="{ name: 'SectionFour', params: { initIndex: 3 } }"  v-bind:class="{ 'is-current': currentNav === 43 }" draggable="false" class="c-Navigation-link t-link u-block">
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-h2">The need to collaborate</span>
                        </cursor-trigger>
                    </router-link>
                </div>

                <!-- section 5 -->

                <div data-id="50" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link v-on:click.native="onRouterLinkClick({ name: 'SectionFive', params: { initIndex: 0 } })" v-bind:to="{ name: 'SectionFive', params: { initIndex: 0 } }"  v-bind:class="{ 'is-current': currentNav === 50 }" draggable="false" class="c-Navigation-link t-link u-block">
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-text--xxs u-font-bold u-uppercase u-marg-b-xs u-hide | u-block@sm">Section 05</span>
                            <span class="t-h2">Jobs and Artificial Intelligence</span>
                        </cursor-trigger>
                    </router-link>
                </div>
                <div data-id="51" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link v-on:click.native="onRouterLinkClick({ name: 'SectionFive', params: { initIndex: 1 } })" v-bind:to="{ name: 'SectionFive', params: { initIndex: 1 } }"  v-bind:class="{ 'is-current': currentNav === 51 }" draggable="false" class="c-Navigation-link t-link u-block">
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-text--xxs u-font-bold u-uppercase u-marg-b-xs u-hide | u-block@sm">Section 05<br>Jobs and Artificial Intelligence</span>
                            <span class="t-h2">Worry over the workforce</span>
                        </cursor-trigger>
                    </router-link>
                </div>
                <div data-id="52" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link v-on:click.native="onRouterLinkClick({ name: 'SectionFive', params: { initIndex: 2 } })" v-bind:to="{ name: 'SectionFive', params: { initIndex: 2 } }"  v-bind:class="{ 'is-current': currentNav === 52 }" draggable="false" class="c-Navigation-link t-link u-block">
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-h2">Adaptation of ai in all business sectors</span>
                        </cursor-trigger>
                    </router-link>
                </div>
                <div data-id="53" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link v-on:click.native="onRouterLinkClick({ name: 'SectionFive', params: { initIndex: 3 } })" v-bind:to="{ name: 'SectionFive', params: { initIndex: 3 } }"  v-bind:class="{ 'is-current': currentNav === 53 }" draggable="false" class="c-Navigation-link t-link u-block">
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-h2">Customer centricity is key</span>
                        </cursor-trigger>
                    </router-link>
                </div>

                <!-- Download Reports -->

                <div data-id="60" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link v-on:click.native="onRouterLinkClick({ name: 'Sections', params: { initIndex: 5 } })" v-bind:to="{ name: 'Sections', params: { initIndex: 5 } }" v-bind:class="{ 'is-current': currentNav === 6 }" draggable="false" class="c-Navigation-link t-link u-block">
                    <!-- <router-link v-bind:to="{ name: 'Sections', params: { initIndex: 5 } }" v-bind:class="{ 'is-current': currentNav === 6 }" draggable="false" class="c-Navigation-link t-link u-block"> -->
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-text--xxs u-font-bold u-uppercase u-marg-b-xs u-hide | u-block@sm">Section 06</span>
                            <span class="t-h2">Download Reports</span>
                        </cursor-trigger>
                    </router-link>
                </div>

                <!-- <div data-id="61" class="js-item c-Navigation-item u-pad-y-lg | u-pad-y-md@sm">
                    <router-link v-bind:to="{ name: 'Sections', params: { initIndex: 5 } }" v-bind:class="{ 'is-current': currentNav === 7 }" draggable="false" class="c-Navigation-link t-link u-block">
                        <cursor-trigger v-bind:name="'view'">
                            <span class="t-text--xxs u-font-bold u-uppercase u-marg-b-xs u-hide | u-block@sm">Section 06</span>
                            <span class="t-h2">2020 Global Innovation Barometer Videos</span>
                        </cursor-trigger>
                    </router-link>
                </div> -->

                <div class="c-Navigation-item u-hide | u-block@sm u-pad-t-sm@sm">
                    <a href="https://www.ge.com/sites/default/files/GE_Global_Innovation_Barometer_2020-Press_Release.pdf" target="_blank" rel="noopener noreferrer" class="t-link--primary t-link--primary--white t-text--xs u-flex u-align-items-c u-marg-t-md">
                        <span>Press Release</span>
                        <div class="c-Header-list-item-icon u-relative">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11" width="11" height="11">
                                <path fill="#fff" d="M7.8 4.1L5.9 6V0h-.8L5 6.1 3.2 4.2l-.6.6 2.8 2.9 3-3z"/>
                                <path d="M8.4 1.8c.2.1.3.2.4.3 1.8 1.8 1.8 4.8 0 6.6s-4.8 1.8-6.6 0S.4 4 2.2 2.1c.1-.1.2-.1.3-.2l-.6-.6c-.1.1-.2.1-.3.2-2.1 2.2-2.1 5.7 0 7.8s5.6 2.2 7.8 0 2.1-5.7 0-7.8c-.1-.1-.3-.2-.4-.3l-.6.6z" opacity=".5" fill="#fff"/>
                            </svg>
                        </div>
                    </a>

                    <a href="https://www.ge.com" target="_blank" rel="noopener noreferrer" class="t-link--primary t-link--primary--white t-text--xs u-marg-t-md u-flex u-align-items-c | u-marg-t-sm@sm">
                        <span>GE.com</span>
                        <div class="c-Header-list-item-icon u-relative">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11" width="11" height="11">
                                <path fill="#fff" d="M7.2 0v.8h2.4l-4 4.1.6.5 4-4v2.5h.8V0z"/>
                                <path d="M9.7 5.3v.5c0 2.4-2 4.4-4.4 4.4S.9 8.2.9 5.8s2-4.4 4.4-4.4h.4V.6h-.4C2.4.6 0 2.9 0 5.8S2.4 11 5.3 11s5.3-2.3 5.3-5.2v-.5h-.9z" opacity=".5" fill="#fff"/>
                            </svg>
                        </div>
                    </a>

                    <div class="u-marg-t-md | u-marg-t-sm@sm">
                        <span class="t-text--xs">Share :</span>
                        <ul class="t-list u-flex">
                            <li><a :href="`https://www.facebook.com/sharer/sharer.php?u=${url}`" target="_blank" rel="noopener noreferrer" class="u-block t-link t-text--xs u-pad-r-sm">Fb</a></li>
                            <li><a :href="`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=&summary=&source=`" target="_blank" rel="noopener noreferrer" class="u-block t-link t-text--xs u-pad-r-sm">In</a></li>
                            <li><a :href="`https://twitter.com/intent/tweet?url=${url}&text=`" target="_blank" rel="noopener noreferrer" class="u-block t-link t-text--xs u-pad-r-sm">Tw</a></li>
                            <li><a :href="`mailto:?&subject=Global Innovation Barometer 2020&body=${url}`" target="_blank" rel="noopener noreferrer" class="u-block t-link t-text--xs">Email</a></li>
                        </ul>
                    </div>
                </div>

            </div>
        </virtual-scroll>

        <div class="c-Navigation-labels c-Container u-absolute u-fit-w u-pointer-none | u-hide@sm">
            <div class="u-relative u-offset-l-w2of16 u-w3of16">
                <span v-bind:class="{ 'is-active': currentItem === 0 }" class="c-Navigation-labels-item t-text--xxs u-font-bold u-uppercase">Home</span>

                <span v-bind:class="{ 'is-active': currentItem === 1 }" class="c-Navigation-labels-item u-absolute u-pos-tl t-text--xxs u-font-bold u-uppercase">Section 01</span>
                <span v-bind:class="{ 'is-active': currentItem === 2 }" class="c-Navigation-labels-item u-absolute u-pos-tl t-text--xxs u-font-bold u-uppercase">Section 01<br>The Future of Innovation</span>
                <span v-bind:class="{ 'is-active': currentItem === 3 }" class="c-Navigation-labels-item u-absolute u-pos-tl t-text--xxs u-font-bold u-uppercase">Section 01<br>The Future of Innovation</span>
                <span v-bind:class="{ 'is-active': currentItem === 4 }" class="c-Navigation-labels-item u-absolute u-pos-tl t-text--xxs u-font-bold u-uppercase">Section 01<br>The Future of Innovation</span>

                <span v-bind:class="{ 'is-active': currentItem === 5 }" class="c-Navigation-labels-item u-absolute u-pos-tl t-text--xxs u-font-bold u-uppercase">Section 02</span>
                <span v-bind:class="{ 'is-active': currentItem === 6 }" class="c-Navigation-labels-item u-absolute u-pos-tl t-text--xxs u-font-bold u-uppercase">Section 02<br>A world of Innovation Nations</span>
                <span v-bind:class="{ 'is-active': currentItem === 7 }" class="c-Navigation-labels-item u-absolute u-pos-tl t-text--xxs u-font-bold u-uppercase">Section 02<br>A world of Innovation Nations</span>
                <span v-bind:class="{ 'is-active': currentItem === 8 }" class="c-Navigation-labels-item u-absolute u-pos-tl t-text--xxs u-font-bold u-uppercase">Section 02<br>A world of Innovation Nations</span>

                <span v-bind:class="{ 'is-active': currentItem === 9 }" class="c-Navigation-labels-item u-absolute u-pos-tl t-text--xxs u-font-bold u-uppercase">Section 03</span>
                <span v-bind:class="{ 'is-active': currentItem === 10 }" class="c-Navigation-labels-item u-absolute u-pos-tl t-text--xxs u-font-bold u-uppercase">Section 03<br>A redefinition of the global innovation influence map</span>
                <span v-bind:class="{ 'is-active': currentItem === 11 }" class="c-Navigation-labels-item u-absolute u-pos-tl t-text--xxs u-font-bold u-uppercase">Section 03<br>A redefinition of the global innovation influence map</span>

                <span v-bind:class="{ 'is-active': currentItem === 12 }" class="c-Navigation-labels-item u-absolute u-pos-tl t-text--xxs u-font-bold u-uppercase">Section 04</span>
                <span v-bind:class="{ 'is-active': currentItem === 13 }" class="c-Navigation-labels-item u-absolute u-pos-tl t-text--xxs u-font-bold u-uppercase">Section 04<br>Protectionism Continues to grow</span>
                <span v-bind:class="{ 'is-active': currentItem === 14 }" class="c-Navigation-labels-item u-absolute u-pos-tl t-text--xxs u-font-bold u-uppercase">Section 04<br>Protectionism Continues to grow</span>
                <span v-bind:class="{ 'is-active': currentItem === 15 }" class="c-Navigation-labels-item u-absolute u-pos-tl t-text--xxs u-font-bold u-uppercase">Section 04<br>Protectionism Continues to grow</span>

                <span v-bind:class="{ 'is-active': currentItem === 16 }" class="c-Navigation-labels-item u-absolute u-pos-tl t-text--xxs u-font-bold u-uppercase">Section 05</span>
                <span v-bind:class="{ 'is-active': currentItem === 17 }" class="c-Navigation-labels-item u-absolute u-pos-tl t-text--xxs u-font-bold u-uppercase">Section 05<br>Jobs and Artificial Intelligence</span>
                <span v-bind:class="{ 'is-active': currentItem === 18 }" class="c-Navigation-labels-item u-absolute u-pos-tl t-text--xxs u-font-bold u-uppercase">Section 05<br>Jobs and Artificial Intelligence</span>
                <span v-bind:class="{ 'is-active': currentItem === 19 }" class="c-Navigation-labels-item u-absolute u-pos-tl t-text--xxs u-font-bold u-uppercase">Section 05<br>Jobs and Artificial Intelligence</span>

                <span v-bind:class="{ 'is-active': currentItem === 20 }" class="c-Navigation-labels-item u-absolute u-pos-tl t-text--xxs u-font-bold u-uppercase">Section 06<br>Download Reports</span>
                <span v-bind:class="{ 'is-active': currentItem === 21 }" class="c-Navigation-labels-item u-absolute u-pos-tl t-text--xxs u-font-bold u-uppercase">Section 06<br>Download Reports</span>
            </div>
        </div>

        <div class="c-Navigation-scroll c-Container u-absolute u-pos-bl u-fit-w u-pointer-none | u-hide@sm">
            <cursor-trigger v-bind:name="'scroll'" class="u-inline-block u-offset-l-w2of16 u-pointer-auto">
                <button v-on:click="onScroll" type="button" class="c-ScrollBtn t-btn t-text--xxs u-font-bold">SCROLL</button>
            </cursor-trigger>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { gsap, Quart } from 'gsap';

    import { disableScroll, enableScroll } from '@/utilities/disableScroll';

    import VirtualScroll from '@/components/VirtualScroll';
    import CursorTrigger from '@/components/CursorTrigger';

    export default {
        name: 'MyNavigation',

        components: {
            VirtualScroll,
            CursorTrigger,
        },

        data() {
            return {
                currentItem: 0,
                offset: 0,
                url: window.location.href,
            };
        },

        computed: {
            ...mapState('window', [
                'height',
            ]),

            ...mapGetters('window', [
                'halfHeight',
                'isMobile',
            ]),

            ...mapState('main', [
                'navigationOpen',
                'currentNav',
            ]),
        },

        watch: {
            'navigationOpen': 'onNavigationOpenChange',
            'currentItem': 'onCurrentItemChange',
        },

        created() {
            this._onKeyUp = this.onKeyUp.bind(this);
        },

        mounted() {
            this.$nextTick(() => {
                this.$items = Array.from(this.$el.querySelectorAll('.js-item'));

                this.$eventHub.$on('update', this.update);
                this.$eventHub.$on('resize', this.resize);

                window.addEventListener('keyup', this._onKeyUp, false);

                this.resize();
            });
        },

        beforeDestroy() {
            this.$eventHub.$off('update', this.update);
            this.$eventHub.$off('resize', this.resize);

            window.removeEventListener('keyup', this._onKeyUp, false);
        },

        methods: {
            ...mapActions('main', [
                'toggleNavigation',
                'setCurrentNav',
            ]),

            resize() {
                if (this.isMobile) {
                    this.offset = 110;
                    return;
                }

                this.items = [];

                this.$items.forEach((el, i) => {
                    let offset = this.items[i-1] ? this.items[i-1].offset + this.items[i-1].height : 0;
                    this.items.push({ height: el.offsetHeight, offset: offset })
                });

                this.offset = (this.height - this.items[0].height) / 2;

                this.$nextTick(() => {
                    this.$refs.virtualScroll.resize();
                });
            },

            update() {
                if (!this.navigationOpen || this.isMobile) return;

                this.items.forEach((el, i) => {
                    this.nextOffset = this.items[i+1] ? this.items[i+1].offset : 99999999;
                    if(this.$refs.virtualScroll.y > (this.items[i].offset - this.halfHeight + this.offset ) && this.$refs.virtualScroll.y < (this.nextOffset - this.halfHeight + this.offset )) {
                        this.currentItem = i;
                    }
                });
            },

            onNavigationOpenChange() {
                if (this.navigationOpen) {
                    disableScroll();

                    // this.$items.forEach((el, i) => {
                    //     if (Number(el.getAttribute('data-id')) === this.currentNav ) {
                    //         let target = this.items[i].offset - this.halfHeight + this.offset + this.items[i].height/2;
                    //         this.currentItem = i;
                    //         this.$refs.virtualScroll.goToScroll(0, target, true);
                    //     }
                    // })
                } else {
                    enableScroll();
                }
            },

            onScroll() {
                let index = this.currentItem + 1;

                if (index < this.$items.length && index >= 0) {
                    let target =  this.items[index].offset - this.halfHeight + this.offset + this.items[index].height/2;
                    this.currentItem = index;
                    this.$refs.virtualScroll.goToScroll(0, target);
                }
            },

            onCurrentItemChange() { // improve onWheel
                clearTimeout(this.timer);

                this.timer = setTimeout(() => {
                    let target =  this.items[this.currentItem].offset - this.halfHeight + this.offset + this.items[this.currentItem].height/2;
                    this.$refs.virtualScroll.goToScroll(0, target);
                }, 500);
            },

            onKeyUp(event) {
                if (!this.navigationOpen) return;

                let index = 0;

                if (event.keyCode === 40 || event.keyCode === 39) {
                    index = this.currentItem + 1;
                } else if (event.keyCode === 38 || event.keyCode === 37) {
                    index = this.currentItem - 1;
                } else {
                    return;
                }

                if (index < this.$items.length && index >= 0) {
                    let target = this.items[index].offset - this.halfHeight + this.offset + this.items[index].height/2;
                    this.currentItem = index;
                    this.$refs.virtualScroll.goToScroll(0, target);
                }
            },

            onSectionsChange(index) {
                let targetRatio = index / 4;

                gsap.to(this, 1.2, { ratio: targetRatio, ease: Quart.easeOut });
            },

            onRouterLinkClick(to) {
                if (this.$route.name === to.name) {
                    this.toggleNavigation(false);
                    this.$eventHub.$emit('route:scroll', to.params.initIndex);
                }

                if (this.$route.name === to.name && to.name === 'Sections' && this.currentNav !== to.params.initIndex + 1) {
                    this.toggleNavigation(false);
                    this.setCurrentNav(to.params.initIndex+1);
                    this.$eventHub.$emit('sections:change', to.params.initIndex);
                    this.$eventHub.$emit('webgl:section', 'reports', 2.4, Quart.easeInOut);
                }
            }
        }
    }
</script>


<style lang="stylus">
    .c-Navigation {
        z-index 1001

        visibility hidden
        transform translateZ(0)

        will-change visibility

        transition visibility 0.8s $ease-out-quart

        .is-transition & {
            transition visibility 0.8s $ease-in-out-quart
        }

        &.is-nav-active {
            opacity 1
            visibility visible

            &:before {
                transform skew(0deg) scaleX(1)  translateZ(0)
            }

            .c-VirtualScroll-progression {
                opacity 1
            }

            // .js-item {
            //     opacity 1
            //     transform translateZ(0)
            // }
        }

        &:before {
            content ""
            position absolute
            top 0
            left 0
            width 100%
            height 100%
            background $colors['blue'];

            transform-origin bottom right
            transform skew(-10deg) scaleX(0)  translateZ(0)
            transition transform 0.8s $ease-in-out-quart
        }

        &-item {
            opacity 0
            transform translateY(80px) translateZ(0)
            will-change transform, opacity
            transition opacity 0.4s $ease-in-quart, transform 0s linear 0.4s

            .is-nav-active & {
                opacity 1
                transform translateZ(0)
            }

            for n in 0..5 {
                &:nth-child({n}) {
                    .is-nav-active & {
                        transition transform 0.8s $ease-out-quart (0.3s + n * 0.1s), opacity 0.8s $ease-out-quart (0.3s + n * 0.1s)
                    }
                }
            }
        }

        &-labels {
            left 0
            top 50%

            opacity 0
            transform translateY(80px) translateZ(0)
            will-change transform, opacity
            transition opacity 0.2s $ease-in-quart, transform 0s linear 0.2s

            .is-nav-active & {
                opacity 1
                transform translateY(-50%) translateZ(0)
                transition transform 0.8s $ease-out-quart 0.4s, opacity 0.8s $ease-out-quart 0.4s
            }

            &-item {
                opacity 0
                transform translateZ(0)
                transition opacity 0.6s $ease-out-quart

                will-change opacity

                &.is-active {
                    opacity 1
                }
            }
        }

        &-scroll {
            margin-bottom 90px

            opacity 0
            transform translateY(80px) translateZ(0)
            will-change transform, opacity
            transition opacity 0.2s $ease-in-quart, transform 0s linear 0.2s

            .is-nav-active & {
                opacity 1
                transform translateZ(0)
                transition transform 0.8s $ease-out-quart 0.7s, opacity 0.8s $ease-out-quart 0.7s
            }
        }

        &-link {
            opacity 0.15
            transform translateZ(0)
            transition opacity 0.6s $ease-out-quart

            will-change opacity

            @media $breakpoints.sm {
                opacity 1
            }

            &:hover {
                opacity 1
            }

            &.is-current {
                opacity 1
                color $colors['black'];
            }
        }

        .c-VirtualScroll-progression {
            opacity 0
            transform translateZ(0)
            will-change opacity
            transition opacity 0.8s $ease-in-quart
        }
    }
</style>