<template>
    <div v-bind:class="{ 'is-active': active }" class="c-Loading u-fixed u-pos-tl u-fit-w u-viewport-fit-h u-color--white u-overflow-h" v-bind:style="{ height: minHeight +'px' }">
        <cursor-follow v-bind:active="active" v-bind:init-x="1" v-bind:init-y="1" v-bind:auto-hide="false" class="u-absolute u-pos-tl u-fit u-flex u-align-items-fe">
            <div class="c-Loading-content c-Container u-fit-w">
                <svg class="c-Loading-line c-Loading-logo u-offset-l-w2of16" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 72 72" xml:space="preserve" width="72" height="72">
                    <path fill="#FFFFFF" d="M57.9 11.2s-5.7-5.3-16.8-4.7c0 0-3.6.5-5.2 2.1-1.7 1.6-4.5 1.9-5.5-.3 0 0-2.3-4.2 6.6-5.2h.5l1.2-.1-2.7-.1c-9.9 0-18.7 4.3-24.8 11.2 0 0-5.3 5.8-4.7 16.9 0 0 .5 3.6 2.1 5.2 1.6 1.7 1.9 4.5-.3 5.5 0 0-4.2 2.3-5.2-6.6v-.5L3 33.4 2.8 36c0 9.9 4.3 18.7 11.2 24.8 0 0 5.8 5.3 16.8 4.7 0 0 3.6-.5 5.2-2.1 1.7-1.7 4.5-1.9 5.5.3 0 0 2.3 4.2-6.6 5.2h-.5l-1.2.1 2.7.1c9.9 0 18.7-4.3 24.8-11.2 0 0 5.3-5.7 4.7-16.8 0 0-.5-3.6-2.1-5.2-1.6-1.7-1.9-4.5.3-5.5 0 0 4.2-2.3 5.2 6.6v.5l.1 1.2.1-2.7c.1-9.8-4.2-18.7-11.1-24.8zM51.6 56c-1.4.6-3 1.1-5.6 1.1 0 0-11.3-1.6-10.7-12.6 0 0 .5-7.7 7.6-11.9 0 0-.5-.4-.5-1.1l-9.7 5.9s.6 11-4.9 16.8c-.8.8-1.8 1.4-2.8 2.1-.7.5-1.5.6-2.1.8-.8.2-1.3.1-2.3.1 0 0-2.7.1-4.2-3.2 0 0-2.6-5.4 2.7-10.9 0 0 3.1-3.9 11-6.5l.1-2.3s-2.8 2.1-4.7 1.7c-1.1-.2-3.7-.4-4.4-4.6 0 0-4.4-1.5-4.7-6 0 0 0-2.7 1.3-3.9 2.2-1.9 1.1 1.2 1.1 1.2-.1 1.9.1 5.2 2.4 5.8 0 0-.1-4.5 2.3-8.2 0 0 4.2-6.2 9-4 0 0 4.5 3.4-1.8 10.4 0 0-2.7 3.7-6.5 4.5 0 0 1.1 4.7 6.5-.8 0 0 1.4-6.5 5.4-4.9 0 0 3.1 2.1-3.5 6.6l-.1 2.4 9-5.6s-.5-4.8.8-8.8c0 0 2.3-5.2 7.3-4.4 0 0 6.1 2.9.3 9.9 0 0-3.3 3.5-5 3.8 0 0 .3 1.3.9 1.8 0 0 7.3-1.5 6.7 1.3 0 0 .3 3.8-7.6 2.4 0 0-5.6 4.7-4.9 9.7 0 0-.6 6.9 6.3 7.9 0 0 7.1.4 6.8-5.8 0 0-.1-5.2-4.4-4.8 0 0-4 1.3-.8 4.8 0 0 1.5 1 .1 2.5 0 0-1.6 1-3.1-.5-.8-.8-1.7-2-1.9-4.6 0 0-.4-6.3 7.2-6.1 0 0 6.9.8 7.1 8.8-.2.3.4 6.6-5.7 9.2z"/>
                    <path fill="#FFFFFF" d="M36 0C16.1 0 0 16.1 0 36s16.1 36 36 36 36-16.1 36-36S55.9 0 36 0zm0 70.7C16.9 70.7 1.3 55.2 1.3 36 1.3 16.9 16.9 1.4 36 1.4S70.6 16.9 70.6 36c0 19.2-15.5 34.7-34.6 34.7z"/>
                    <path fill="#FFFFFF" d="M48.4 23.1c.7-1.2 1.6-2.7.1-3.8 0 0-3.6-.5-3.8 7.7 0 0 2.4-1.9 3.7-3.9zM18.9 50.3s0 1.1.3 1.8 1 .9 1.6 1.4c0 0 3.9 1.8 7.5-6.8 0 0 1.4-3.5 1.3-6.8 0 0-10.5 5.1-10.7 10.4zM30.6 20.5s.2-1-.5-1.3c0 0-4.9.5-5.6 8.7-.1 0 6.2-4.1 6.1-7.4z"/>
                </svg>
                <div class="u-flex u-flex-flow-row-wrap u-marg-t-xl">
                    <div class="c-Loading-line c-Loading-line--2 u-w4of16 u-offset-l-w2of16 | u-w5of16@lg | u-w6of8@sm u-offset-l-w1of8@sm">
                        <p class="t-h2">State of Innovation around the World</p>
                    </div>
                    <div class="c-Loading-content-desc c-Loading-line c-Loading-line--2 u-w3of16 u-offset-l-w4of16 | u-w5of16@md | u-w4of16@lg u-offset-l-w2of16@lg | u-w6of8@sm u-offset-l-w1of8@sm">
                        <p class="t-text--sm">Loading GE’s global study that explores the current innovation atmosphere around the world.</p>
                    </div>
                </div>
            </div>

            <template v-slot:cursor>
                <div class="c-Loading-cursor t-text--xs u-flex u-align-items-c u-justify-content-c">
                    <span>{{ pourcent }}<sup>%</sup></span>
                </div>
            </template>
        </cursor-follow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { gsap, Linear} from 'gsap';
    import { disableScroll, enableScroll } from '@/utilities/disableScroll';

    import CursorFollow from '@/components/CursorFollow';

    export default {
        name: 'MyLoading',

        components: {
            CursorFollow,
        },

        data() {
            return {
                pourcent: '00',
                active: true,
            };
        },

        computed: {
            ...mapState('window', [
                'minHeight',
            ]),
        },

        mounted() {
            disableScroll();

            this.$nextTick(() => {
                this.load();
            });
        },

        methods: {
            load() {
                this.smoothPourcent = { value: 0 };

                gsap.to(this.smoothPourcent, 2, {
                    value: 100,
                    ease:Linear.easeNone,
                    onUpdate: () => {
                        if (this.smoothPourcent.value < 10) {
                            this.pourcent = '0' + Math.round(this.smoothPourcent.value);
                        } else {
                            this.pourcent = Math.round(this.smoothPourcent.value);
                        }
                    },
                    onComplete: () => {
                        this.active = false;

                        setTimeout(() => {
                            this.$eventHub.$emit('page:loaded');
                            enableScroll();
                        }, 800);
                    },
                });
            },
        }
    }
</script>

<style lang="stylus">
    .c-Loading {
        z-index 9998

        opacity 0
        visibility hidden
        transform translateZ(0)

        will-change opacity, visibility
        transition opacity 0.8s $ease-in-quart, visibility 0.8s $ease-in-quart

        &.is-active {
            opacity 1
            visibility visible
            transition none
        }

        &-content {
            margin-bottom 180px

            @media $breakpoints.sm {
                margin-bottom 95px
            }

            &-desc {
                @media $breakpoints.sm {
                    margin-top 40px
                }
            }
        }

        &-logo {
            width 54px
            height 54px
        }

        &-line {
            will-change transform
            transform translateY(-80px) translateZ(0)
            transition transform 0.8s $ease-in-quart

            &--2 {
                transition-delay 0.1s
            }

            .is-active & {
                transform translateZ(0)
                transition none
            }
        }

        &-cursor {
            width 42px
            height 42px

            border-radius 50%
            background $colors['white'];
            color $colors['black'];

            opacity 0
            transition opacity 0.6s $ease-out-quart

            .is-cursor-active & {
                opacity 1
            }

            span {
                line-height 1
            }

            sup {
                top -0.2em
            }
        }
    }
</style>