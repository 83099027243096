<template>
    <div class="c-Home u-relative u-fit-w" v-bind:class="{ 'is-page-ready': isPageReady }">

        <section class="js-section js-ui-switch c-Home-intro u-fit-w u-viewport-fit-h u-color--white u-overflow-h" v-bind:style="{ height: minHeight +'px' }">
            <parallax-object v-bind:active="isPageReady" v-bind:ratio="-0.8" class="u-absolute u-pos-tl u-fit  u-flex u-align-items-fe">
                <div class="c-Home-intro-content c-Container u-fit-w u-flex u-flex-flow-row-wrap">
                    <div class="u-w4of16 u-offset-l-w2of16 | u-w5of16@lg | u-w6of8@sm u-offset-l-w1of8@sm">
                        <p class="c-Home-intro-line t-h2">Global Innovation Barometer 2020</p>
                    </div>
                    <div class="c-Home-intro-content-desc u-w3of16 u-offset-l-w4of16 | u-w4of16@lg u-offset-l-w2of16@lg | u-w5of16@md | u-w6of8@sm u-offset-l-w1of8@sm">
                        <p class="c-Home-intro-line t-text--sm">Explore the state of innovation today to examine what the world’s most innovative leaders are doing to succeed. </p>
                    </div>

                </div>
                <div class="c-Home-intro-scroll c-Container u-absolute u-pos-bl u-fit-w">
                    <cursor-trigger v-bind:name="'scroll'" class="u-inline-block u-offset-l-w2of16 ">
                        <button v-on:click="onScroll" type="button" class="c-Home-intro-line c-Home-intro-line--2 c-ScrollBtn t-btn t-text--xxs u-font-bold">SCROLL</button>
                    </cursor-trigger>
                </div>
            </parallax-object>
        </section>

        <section class="js-section js-ui-switch c-Home-head c-Container u-relative u-bg--white">
            <div class="u-w9of16 u-offset-l-w3of16 | u-fit-w@sm u-marg-l-0@sm">
                <h2 ref="title" class="c-Home-head-title t-h1">
                    <span class="c-Home-head-line">Pride and</span>
                    <span class="c-Home-head-line u-offset-l-w2of9 u-marg-l-0@sm">Protectionism</span>
                </h2>
                <div class="u-offset-l-w2of9 | u-w6of8@sm u-offset-l-w1of8@sm">
                    <h3 class="c-Home-head-subtitle t-h4 u-color--blue">A Quest for <span class="u-block@sm u-offset-l-w1of12@sm">Innovation Agency</span></h3>

                    <cursor-trigger v-bind:name="'scroll-dark'" class="c-Home-head-scroll u-inline-block | u-offset-l-w1of12@sm">
                        <button v-on:click="onScroll" type="button" class="c-ScrollBtn c-ScrollBtn--black t-btn t-text--xxs u-font-bold">SCROLL</button>
                    </cursor-trigger>
                </div>
            </div>
        </section>

        <section class="js-section c-Home-content c-Container u-relative u-bg--white u-overflow-h">
            <div class="u-w3of16 u-offset-l-w1of16 | u-w4of16@lg | u-w5of16@md | u-w11of16@sm u-offset-l-w1of16@sm">
                <p class="t-text--sm u-u-opacity--50">The world's most innovative leaders were surveyed, and the results are in. There are emerging players, technologies and challenges.</p>
            </div>

            <div class="u-fit-w u-relative">
                <p class="c-Home-content-second t-h4 u-w6of16 u-offset-l-w1of16 | u-w7of16@lg | u-w8of16@md  | u-w8of16@md | u-w14of16@sm u-offset-l-w1of16@sm">Entering 2020, nations globally made strong progress on innovation. Despite challenges, including COVID-19, setbacks to innovation pace, mobility restrictions and self-confidence, innovation continues to be a priority.</p>

                <div class="c-Home-content-medias | u-relative@md">
                    <parallax-object v-bind:ratio="0.1" class="c-Home-content-img u-absolute u-pos-tl u-w5of16 u-offset-l-w10of16 | u-w10of16@sm u-offset-l-w5of16@sm">
                        <div class="c-Home-content-img-inner u-fit-w t-b-radius-md">
                            <img :src="`${publicPath}static/images/home/2.jpg`" alt="" class="u-absolute u-pos-tl u-fit">
                        </div>
                    </parallax-object>

                    <parallax-object v-bind:ratio="0.2" class="c-Home-content-gradient u-absolute u-pos-tl u-w3of16 u-offset-l-w9of16 | u-w6of16@sm u-offset-l-w3of16@sm">
                        <div class="c-Home-content-gradient-inner u-fit-w t-b-radius-sm"></div>
                    </parallax-object>

                    <div class="c-Home-content-video u-w12of16 u-offset-l-w2of16 | u-fit-w@sm u-marg-l-0@sm">
                        <video-observer class="c-Home-content-video-container t-b-radius-lg">
                            <video-responsive>
                                <video class="js-video u-absolute u-pos-tl u-fit" :poster="`${publicPath}static/images/home/pride_protectionism-poster.jpg`" preload="auto" loop muted playsinline>
                                    <source :src="`${publicPath}static/videos/home/pride_protectionism.webm`" type="video/webm">
                                    <source :src="`${publicPath}static/videos/home/pride_protectionism.mp4`" type="video/mp4">

                                    <source :srcset="`${publicPath}static/videos/home/pride_protectionism-mobile.webm`" type="video/webm" media="(max-width: 767px)">
                                    <source :srcset="`${publicPath}static/videos/home/pride_protectionism-mobile.mp4`" type="video/mp4" media="(max-width: 767px)">
                                </video>
                            </video-responsive>
                        </video-observer>
                    </div>
                </div>

                <p class="t-h4 u-w6of16 u-offset-l-w1of16  | u-w7of16@lg | u-w8of16@md | u-w14of16@sm u-offset-l-w1of16@sm">Protectionist sentiment remains and is seen as an important element in helping countries recover from the pandemic.</p>
            </div>
        </section>

        <section class="js-section c-Home-foot c-Container u-relative u-bg--white u-overflow-h">
            <div class="u-relative u-fit-w">
                <div class="c-Home-foot-title u-w5of16 u-offset-l-w5of16 | u-w6of8@sm u-offset-l-w1of8@sm">
                    <h3 class="t-h3">This dynamic is seeding an ‘innovation realignment’ on a global scale</h3>
                </div>

                <div class="c-Home-foot-img u-w10of16 u-offset-l-w3of16 | u-w14of16@sm u-offset-l-w1of16@sm">
                    <div class="c-Home-foot-img-inner u-fit-w t-b-radius-lg">
                        <picture class="u-absolute u-pos-tl u-fit">
                            <source media="(max-width: 767px)" :srcset="`${publicPath}static/images/home/foot-mobile.jpg`">
                            <source media="(min-width: 768px)" :srcset="`${publicPath}static/images/home/foot.jpg`">
                            <img :src="`${publicPath}static/images/home/foot.jpg`" alt="" class="u-absolute u-pos-tl u-fit">
                        </picture>
                    </div>
                </div>

                <parallax-object v-bind:ratio="0.1" class="c-Home-foot-gradient u-w6of16 u-offset-l-w1of16 u-absolute u-pos-tl t-b-radius-md | u-w5of8@sm u-marg-l-0@sm"></parallax-object>

                <div class="u-w6of16 u-offset-l-w5of16 | u-w7of16@lg | u-w8of16@md | u-w14of16@sm u-offset-l-w1of16@sm">
                    <p class="t-h4">Time will tell if Business Executives can reconcile their aspiration to protect homegrown innovation with their heavy reliance on global talent, funding and technology.</p>
                </div>

                <div class="c-Home-foot-text u-w4of16 u-offset-l-w5of16 | u-w5of16@md | u-w12of16@sm u-offset-l-w1of16@sm">
                    <cursor-trigger v-bind:name="'scroll-dark'" class="c-Home-foot-scroll u-inline-block | u-offset-l-w1of14@sm">
                        <router-link to="/sections" type="button" class="c-ScrollBtn c-ScrollBtn--black t-btn t-text--xxs u-font-bold">SCROLL</router-link>
                    </cursor-trigger>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import scrollToY from '@/utilities/scrollToY';
    import normalizeWheel from '@/utilities/normalizeWheel';
    import getOffsetTop from '@/utilities/getOffsetTop';
    import getPrefix from '@/utilities/getPrefix';
    import clamp from '@/utilities/clamp';

    import CursorTrigger from '@/components/CursorTrigger';
    import ParallaxObject from '@/components/ParallaxObject';
    import VideoObserver from '@/components/VideoObserver';
    import VideoResponsive from '@/components/VideoResponsive';

    export default {
        name: 'Home',

        components: {
            CursorTrigger,
            ParallaxObject,
            VideoObserver,
            VideoResponsive,
        },

        data() {
            return {
                publicPath: process.env.BASE_URL,
                isPageReady: false,
            };
        },

        computed: {
            ...mapState('window', [
                'height',
                'minHeight',
                'isTouch',
            ]),

            ...mapState('main', [
                'navigationOpen',
            ]),
        },

        created() {
            this._onWheel = this.onWheel.bind(this);

            this._onDragStart = this.onDragStart.bind(this);
            this._onDrag = this.onDrag.bind(this);
            this._onDragEnd = this.onDragEnd.bind(this);
        },

        mounted() {
            this.$eventHub.$on('page:ready', this.onPageReady);
            this.$eventHub.$on('resize', this.resize);
            this.$eventHub.$on('update', this.update);

            this.setCurrentNav(0);

            if (!this.isTouch) {
                window.addEventListener('wheel', this._onWheel, false);
            } else {
                window.addEventListener('touchstart', this._onDragStart);
                window.addEventListener('touchmove', this._onDrag);
                window.addEventListener('touchend', this._onDragEnd);
            }

            this.$nextTick(() => {
                this.$eventHub.$emit('page:enter');
            });
        },

        beforeDestroy() {
            this.$eventHub.$off('page:ready', this.onPageReady);
            this.$eventHub.$off('resize', this.resize);
            this.$eventHub.$off('update', this.update);

            if (!this.isTouch) {
                window.removeEventListener('wheel', this._onWheel);
            } else {
                window.removeEventListener('touchstart', this._onDragStart);
                window.removeEventListener('touchmove', this._onDrag);
                window.removeEventListener('touchend', this._onDragEnd);
            }
        },

        methods: {
            ...mapActions('main', [
                'setCurrentNav'
            ]),

            onScroll() {
                scrollToY(this.$root.scrollTop + this.minHeight);
            },

            onPageReady() {
                this.isPageReady = true;
                this.$eventHub.$emit('webgl:section', 'landing');
            },

            onWheel(event) {
                if(this.$root.smoothScroll < this.$root.scrollHeight - this.height -1 || !this.isPageReady || this.navigationOpen) return;

                const normalizeValues = normalizeWheel(event);

                if ((normalizeValues.pixelY > 10 || normalizeValues.pixelY < -10)) {
                    if (normalizeValues.pixelY > 10) {
                        this.$router.push('/sections');
                    }
                }
            },

            onDragStart(event) {
                if (!this.isPageReady || this.navigationOpen) return;

                this.isDrag = true;

                this.touchEvent = this.isTouch && event.type !== 'mousemove' ? (event.touches[0] || event.changedTouches[0]) : event;
                this.dragStart = this.touchEvent.clientY;
                this.delta = 0;
            },

            onDrag(event) {
                if (!this.isDrag) return;

                this.touchEvent = this.isTouch && event.type !== 'mousemove' ? (event.touches[0] || event.changedTouches[0]) : event;
                this.delta = this.touchEvent.clientY - this.dragStart;
            },

            onDragEnd() {
                if (!this.isDrag) return;

                this.isDrag = false;

                if(this.$root.smoothScroll < this.$root.scrollHeight - this.height -1) return;

                if (Math.abs(this.delta) > this.minHeight / 5) {
                    if (this.delta < 0) {
                        this.$router.push('/sections');
                    }
                }
            },

            resize() {
                if (this.isTouch) return;

                this.startY = this.minHeight;
                this.titleHeight = this.$refs.title.offsetHeight
                this.endY = this.titleHeight + getOffsetTop(this.$refs.title) - this.height/2 - this.titleHeight/2 ;

                this.scaleAmount = 1.7;
            },

            update() {
                if (!this.endY || this.isTouch) return;

                this.scaleVal = 1 - (((this.$root.smoothScroll - this.startY) + this.height) / this.endY);
                this.scaleVal = ( (this.scaleVal - 0) / (1 - 0) ) * (this.scaleAmount - 1) + 1; // remap range
                this.scale = clamp(this.scaleVal, 1, this.scaleAmount);

                if (this.scaleVal >= 1) {
                    this.$refs.title.style[getPrefix('transform')] = `scale(${this.scale}) translateZ(0)`;
                }
            },
        },
    }
</script>

<style lang="stylus">
    .c-Home {
        &-intro {
            &-content {
                margin-bottom 180px

                @media $breakpoints.sm {
                    margin-bottom 95px
                }

                &-desc {
                    @media $breakpoints.sm {
                        margin-top 40px
                    }
                }
            }

            &-line {
                opacity 0
                transform translateY(80px) translateZ(0)
                will-change transform, opacity

                transition transform 0.8s $ease-out-quart, opacity 0.8s $ease-out-quart

                &--2 {
                    transition transform 0.8s $ease-out-quart 0.1s, opacity 0.8s $ease-out-quart 0.1s
                }

                .is-page-ready & {
                    opacity 1
                    transform translateZ(0)
                }
            }

            &-scroll {
                margin-bottom 90px

                @media $breakpoints.sm {
                    margin-bottom 42px
                }
            }
        }

        &-head {
            z-index 1
            padding-top 290px
            padding-bottom 160px

            @media $breakpoints.sm {
                padding-top 247px
            }

            &-title {
                transform-origin bottom
                will-change transform
                transform translateZ(0)
            }

            &-line {
                display block
            }

            &-subtitle {
                margin-top 65px

                @media $breakpoints.sm {
                    margin-top 35px
                }
            }

            &-scroll {
                margin-top 120px

                @media $breakpoints.sm {
                    margin-top 90px
                }
            }
        }

        &-content {
            z-index 2
            padding-bottom 160px

            &-second {
                margin-top 65px
            }

            &-medias {
                @media $breakpoints.sm {
                    margin 145px 0
                }
            }

            &-gradient {
                top 140px
                padding-right 4.16%

                @media $breakpoints.md {
                    top 60px
                }

                @media $breakpoints.sm {
                    padding-right 0
                    top 102px
                }

                &-inner {
                    aspect-ratio(384, 228)
                    overflow hidden

                    backdrop-filter: blur(7px);
                    background linear-gradient(to bottom, #0060b4, rgba(#0eb5df, 0.1));
                }
            }

            &-img {
                top -25px
                padding 0 2.78%

                @media $breakpoints.sm {
                    padding 0
                    top 0
                }

                &-inner {
                    aspect-ratio(680, 856)
                    overflow hidden
                }
            }

            &-video {
                padding 160px 1.38%

                @media $breakpoints.sm {
                    padding-top 231px
                    padding-bottom 0
                    padding-right 0
                    padding-left 0
                }

                &-container {
                    aspect-ratio(16, 9)
                    overflow hidden
                    background $colors['black'];

                    @media $breakpoints.sm {
                        aspect-ratio(374, 664)
                    }
                }
            }
        }

        &-foot {
            z-index 3
            padding-bottom 110px

            @media $breakpoints.sm {
                padding-bottom 95px
            }

            &-title {
                position relative
                z-index 3
            }

            &-img {
                margin-bottom 20px
                position relative
                top -65px
                padding 0 2.78%

                z-index 1

                @media $breakpoints.sm {
                    margin-bottom 30px
                    padding 0
                    top -48px
                }

                &-inner {
                    aspect-ratio(1530, 840)
                    overflow hidden

                    @media $breakpoints.sm {
                        aspect-ratio(594, 748)
                    }
                }
            }

            &-gradient {
                z-index 2

                aspect-ratio(530, 300)
                position absolute
                overflow hidden

                top 50px

                backdrop-filter: blur(7px);
                background linear-gradient(to top, #0060b4, rgba(#0eb5df, 0.1));

                @media $breakpoints.sm {
                    aspect-ratio(423, 472)
                    position absolute
                    top 70px
                }
            }

            &-text {
                margin-top 5px

                @media $breakpoints.sm {
                    margin-top 0
                }
            }

            &-scroll {
                margin-top 110px

                @media $breakpoints.sm {
                    margin-top 95px
                }
            }
        }
    }
</style>