<template>
    <div class="c-Webgl u-fixed u-pos-tl u-fit u-overflow-h u-bg--black" v-bind:class="{ 'is-active': show }" v-bind:style="{ height: minHeight + 'px' }">
        <generalelectric-ib2020-webgl ref="webgl" class="u-absolute u-pos-tl u-fit"></generalelectric-ib2020-webgl>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { Quart } from 'gsap';

    import '@damienmortini/generalelectric-ib2020-webgl/src/index.js';

    export default {
        name: 'BackgroundWebgl',

        data() {
            return {
                show: true,
            };
        },

        computed: {
            ...mapState('window', [
                'minHeight'
            ]),

            ...mapState('main', [
                'navigationOpen',
                'isTransition',
            ]),
        },

        watch: {
            'navigationOpen': 'onNavigationChange',
        },

        created() {
            this.isPlaying = true;
        },

        mounted() {
            this.$nextTick(() => {
                this.$refs.webgl.section = 'loading';
                this.$refs.webgl.play();
            });

            this.$eventHub.$on('update', this.update);

            this.$eventHub.$on('webgl:section', this.onSectionChange);
            this.$eventHub.$on('webgl:pause', this.onPause)
            this.$eventHub.$on('webgl:play', this.onPlay)
        },

        beforeDestroy() {
            this.$eventHub.$off('update', this.update);

            this.$eventHub.$off('webgl:section', this.onSectionChange);
            this.$eventHub.$off('webgl:pause', this.onPause)
            this.$eventHub.$off('webgl:play', this.onPlay)
        },

        methods: {
            update() {
                if (!this.isPlaying || this.isTransition) return;

                if (this.$root.smoothScroll > this.minHeight) {
                    if (this.show) {
                        this.show = false;
                        this.$refs.webgl.pause();
                    }
                } else {
                    if (!this.show) {
                        this.show = true;
                        this.$refs.webgl.play();
                    }
                }
            },

            onSectionChange(section, duration = 1.8, easing = Quart.easeOut) {
                this.isPlaying = true;
                this.show = true;

                this.$refs.webgl.animateTo(section, { duration: duration, ease: easing });
                this.$refs.webgl.play();
            },

            onPause() {
                this.isPlaying = false;

                this.show = false;
                this.$refs.webgl.pause();
            },

            onPlay() {
                this.isPlaying = true;

                this.show = true;
                this.$refs.webgl.play();
            },

            onNavigationChange() {
                clearTimeout(this.timerNav);

                if (this.navigationOpen) {
                    this.timerNav = setTimeout(() => {
                        this.$refs.webgl.pause();
                    }, 800);
                } else {
                    if(this.show && this.isPlaying) {
                        this.$refs.webgl.play();
                    }
                }
            }
        }
    }
</script>

<style lang="stylus">
    .c-Webgl {
        visibility hidden
        will-change visibility

        @media $breakpoints.sm {
            visibility visible
        }

        &.is-active {
            visibility visible
        }
    }
</style>