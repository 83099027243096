const isTouch = 'ontouchstart' in document.documentElement;
const pointer = !!window.navigator.pointerEnabled;
// const msPointer = !!window.navigator.msPointerEnabled;

export var pointerdown = isTouch ? 'touchstart' : (pointer ? 'pointerdown' : 'mousedown' );
export var pointerup = isTouch ? 'touchend' : (pointer ? 'pointerup' : 'mouseup' );
export var pointermove = isTouch ? 'touchmove' : (pointer ? 'pointermove' : 'mousemove' );
export var pointerenter = isTouch ? 'touchstart' : (pointer ? 'pointerenter' : 'mouseenter' );
export var pointerleave = isTouch ? 'touchend' : (pointer ? 'pointerleave' : 'mouseleave' );
export var pointerover = isTouch ? 'touchstart' : (pointer ? 'pointerover' : 'mouseover' );
export var pointerout = isTouch ? 'touchend' : (pointer ? 'pointerout' : 'mouseout' );

export default { pointerdown, pointerup, pointermove, pointerenter, pointerleave, pointerover, pointerout };