<template>
    <div>
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'VideoResponsive',

        created() {
            this.regexExtension = /[^\\]*\.(\w+)$/;

            this._init = this.init.bind(this);
        },

        mounted() {
            this.$nextTick(() => {
                this.$video = this.$el.querySelector('.js-video');
                this.$video.addEventListener('loadedmetadata', this._init);

                this.$sources = Array.from(this.$video.getElementsByTagName('source'));

                this.$eventHub.$on('resize', this.resize);
            });
        },

        beforeDestroy() {
            this.$eventHub.$off('resize', this.resize);
            this.$video.removeEventListener('loadedmetadata', this._init);
        },

        methods: {
            init() {
                this.init = true;
                this.$video.removeEventListener('loadedmetadata', this._init);

                this.defaultSrc = this.$video.currentSrc;
                this.defaultType = this.defaultSrc.match(this.regexExtension)[1];

                this.resize();
            },

            resize() {
                if(!this.init) return;

                let mediaMatch = false;

                for (let i = 0; i < this.$sources.length; i++) {
                    if (this.$sources[i].hasAttribute('srcset')) {
                        let src = this.$sources[i].getAttribute('srcset');
                        let type = src.match(this.regexExtension)[1];

                        if (type === this.defaultType && window.matchMedia(this.$sources[i].getAttribute('media')).matches) {
                            mediaMatch = true;

                            if (this.$video.getAttribute('src') !== src) {
                                this.$video.setAttribute('src', src);

                                this.$video.load();
                                this.$video.play();

                                 break;
                            }
                        }
                    }
                }

                if (!mediaMatch) {
                    if (this.$video.getAttribute('src') && this.$video.getAttribute('src') !== this.defaultSrc) {
                        this.$video.setAttribute('src', this.defaultSrc);

                        this.$video.load();
                        this.$video.play();
                    }
                }
            },
        },
    }
</script>