<template>
    <div class="c-NavigationBtn u-fixed u-overflow-h">
        <button ref="toggleBtn" aria-label="menu buttons" v-on:click="contentOverlayOpen ? toggleContentOverlayOpen(false) : toggleNavigation(!navigationOpen)" v-bind:class="{ 'is-active': show, 'is-dark': isDark, 'is-close': navigationOpen, 'is-content-overlay': contentOverlayOpen }" class="c-NavigationBtn-inner u-relative u-block t-btn u-flex u-align-items-c u-justify-content-c">
            <!-- <svg class="c-NavigationBtn-inner-bg u-absolute u-pos-tl"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72"  width="72" height="72">
                <circle class="c-NavigationBtn-inner-circle-bg" cx="36" cy="36" r="35" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10"/>
                <circle class="c-NavigationBtn-inner-circle-bg c-NavigationBtn-inner-circle-bg--dark" cx="36" cy="36" r="35" fill="none" stroke="#0c1c2a" stroke-linecap="round" stroke-miterlimit="10"/>
                <circle ref="circle" class="c-NavigationBtn-inner-circle-line" cx="36" cy="36" r="35" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10"/>
            </svg> -->

            <div class="c-NavigationBtn-inner-bg u-absolute u-pos-tl">
                <canvas ref="canvas" class="c-NavigationBtn-canvas u-absolute u-pos-tl u-fit"></canvas>
            </div>

            <div class="u-relative">
                <div class="c-NavigationBtn-inner-icon"></div>
                <div class="c-NavigationBtn-inner-icon c-NavigationBtn-inner-icon--dark u-absolute u-pos-tl"></div>
            </div>
        </button>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import { gsap, Quart } from 'gsap';

    import getOffsetTop from '@/utilities/getOffsetTop';

    export default {
        name: 'NavigationBtn',

        data() {
            return {
                show: false,
                isDark: false,

                index: 0,
            };
        },

        computed: {
            ...mapState('window', [
                'height',
                'width',
            ]),

            ...mapState('main', [
                'navigationOpen',
                'contentOverlayOpen',
            ]),
        },

        watch: {
            'index': 'onIndexChange',
            '$route': 'onRouteChange',
        },

        created() {
            this.ratio = null;

            this.cuePoints = [];
            this.$switchElements = [];
        },

        mounted() {
            this.$eventHub.$on('update', this.update);
            this.$eventHub.$on('resize', this.resize);

            this.$eventHub.$on('header:show', this.onShow);
            this.$eventHub.$on('header:hide', this.onHide);
            this.$eventHub.$on('header:reset', this.onReset);

            this.$eventHub.$on('sections:change', this.onSectionsChange);

            this.ctx = this.$refs.canvas.getContext('2d');
        },

        beforeDestroy() {
            this.$eventHub.$off('update', this.update);
            this.$eventHub.$off('resize', this.resize);

            this.$eventHub.$off('header:show', this.onShow);
            this.$eventHub.$off('header:hide', this.onHide);
            this.$eventHub.$off('header:reset', this.onReset);

            this.$eventHub.$off('sections:change', this.onSectionsChange);
        },

        methods: {
            ...mapActions('main', [
                'toggleNavigation',
                'toggleContentOverlayOpen',
            ]),

            onShow() {
                this.isTransition = false;
                this.show = true;
            },

            onHide() {
                this.show = false;
            },

            resize() {
                this.offsetTop = getOffsetTop(this.$refs.toggleBtn);
                this.halfHeight = this.$refs.toggleBtn.offsetHeight/2;

                this.offsetCuePoint = this.halfHeight + this.offsetTop;

                this.cuePoints = [];

                this.$switchElements.forEach((el) => {
                    this.cuePoints.push(getOffsetTop(el));
                });

                // canvas

                this.size = this.width < 1024 ? 54 : 72;

                if (window.devicePixelRatio) {
                    this.$refs.canvas.setAttribute('width', this.size * window.devicePixelRatio);
                    this.$refs.canvas.setAttribute('height', this.size * window.devicePixelRatio);
                    this.$refs.canvas.style.width =  this.size + 'px';
                    this.$refs.canvas.style.height =  this.size + 'px';

                    this.ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
                }
            },

            update() {
                this.cuePoints.forEach((offset, i) => {
                    let nextCuePoints = this.cuePoints[i+1] ? this.cuePoints[i+1] : 99999999;

                    if (this.$root.smoothScroll > this.cuePoints[i] - this.offsetCuePoint && this.$root.smoothScroll < nextCuePoints - this.offsetCuePoint ) {
                        this.index = i;
                    }
                });


                if (!this.isTransition && this.$route.name !== 'Sections') {
                    this.ratio = this.$root.smoothScroll / (this.$root.scrollHeight - this.height);
                    this.ratio = Math.round(this.ratio * 1000) / 1000;
                }

                // this.$refs.circle.style.strokeDashoffset = 220 - 220 * this.ratio;

                // canvas
                if(!this.ctx) return;

                this.ctx.clearRect(0, 0, this.size, this.size);

                this.angle = (this.ratio * 365) * (Math.PI/180)

                this.startAngle = -Math.PI/2;
                this.endAngle = this.angle + this.startAngle;

                this.ctx.beginPath();
                this.ctx.arc(this.size/2, this.size/2, this.size/2-1, this.startAngle, this.endAngle, false);
                this.ctx.lineWidth = 1;
                this.ctx.strokeStyle = this.isDark  ? '#0c1c2a' : '#FFFFFF'
                this.ctx.stroke();
                this.ctx.closePath();

            },

            onReset() {
                this.ratio = 0;
                this.index = 0;
                this.isDark = false;
                this.cuePoints = [];
                this.$switchElements = Array.from(document.querySelectorAll('.js-ui-switch'));
            },

            onRouteChange() {
                this.ratio = 0;
                this.isDark = false;
                this.isTransition = true;
            },

            onIndexChange() {
                if(this.isTransition) return;

                if(this.index === 0) {
                    this.isDark = false;
                } else {
                    this.isDark = !this.isDark;
                }
            },

            onSectionsChange(index) {
                let targetRatio = index / 4;

                gsap.to(this, 1.2, { ratio: targetRatio, ease: Quart.easeOut });
            },
        }
    }
</script>


<style lang="stylus">
    .c-NavigationBtn {
        z-index 1002

        top 50%
        right 32px

        width 88px
        height 88px

        margin-top -44px

        @media $breakpoints.md {
            width 54px
            height 54px

            margin-top 0

            top: 40px;
            right: 40px;
        }

        @media $breakpoints.sm {
            width 54px
            height 54px

            margin-top 0

            top 18px
            right 18px
        }

        &-canvas {
            opacity 1
            transition opacity 0.6s $ease-out-quart
            transform  translateZ(0)
            will-change opacity

            .is-close & {
                opacity 0
            }

            .is-content-overlay & {
                opacity 0
            }
        }

        &-inner {
            margin-top 8px
            margin-left 8px

            width 72px
            height 72px

            border-radius 50%

            opacity 0
            visibility hidden
            transition opacity 0.8s $ease-out-quart, visibility 0.8s $ease-out-quart

            @media $breakpoints.md {
                width 54px
                height 54px
                margin-top 0
                margin-left 0
            }

            &.is-active {
                opacity 1
                visibility visible
            }

            &:hover {
                .c-NavigationBtn-inner-bg {
                    transform scale(1.2) translateZ(0)

                    @media $breakpoints.md {
                        transform scale(1) translateZ(0)
                    }
                }
            }

            &-bg {
                transition transform 0.6s $ease-out-quart
                transform scale(1) translateZ(0)
                will-change transform

                width 72px
                height 72px

                @media $breakpoints.md {
                    width 54px
                    height 54px
                }

                &:before {
                    content ""
                    position absolute
                    top 0px
                    left 0px
                    width 100%
                    height 100%
                    border 1px solid $colors['black'];
                    border-radius 50%
                    opacity 0

                    .is-dark & {
                        opacity 0.15
                    }

                    .is-close & {
                        opacity 0
                    }

                    .is-content-overlay & {
                        opacity 0.15
                    }
                }

                &:after {
                    content ""
                    position absolute
                    top 0px
                    left 0px
                    width 100%
                    height 100%
                    border 1px solid $colors['white'];
                    border-radius 50%
                    opacity 0.15

                    .is-dark & {
                        opacity 0
                    }

                    .is-close & {
                        opacity 0.15
                    }

                    .is-content-overlay & {
                        opacity 0
                    }
                }
            }

            // &-circle {
            //     &-line {
            //         stroke-dasharray 220

            //         transform: rotate(-90deg) translateZ(0);
            //         transform-box: fill-box;
            //         transform-origin: center;

            //         transition opacity 0.6s $ease-out-quart
            //         will-change opacity, stroke-dashoffset

            //         .is-dark & {
            //             stroke $colors['black'];
            //         }

            //         .is-close & {
            //             opacity 0
            //         }
            //     }

            //     &-bg {
            //         opacity 0.15
            //         transform translateZ(0);
            //         transition opacity 0.6s $ease-out-quart

            //         .is-dark & {
            //             opacity 0
            //         }

            //         .is-close & {
            //             opacity 0.15
            //         }

            //         &--dark {
            //             opacity 0
            //             transform translateZ(0);
            //             transition opacity 0.6s $ease-out-quart

            //             .is-dark & {
            //                 opacity 0.15
            //             }

            //             .is-close & {
            //                 opacity 0
            //             }
            //         }
            //     }
            // }

            &-icon {
                position relative
                width 14px
                height 7px

                transform translateZ(0);
                transition opacity 0.6s $ease-out-quart

                &:before, &:after {
                    content ""
                    position absolute
                    top 0
                    left 0

                    width 100%
                    height 1px
                    background $colors['white'];

                    transform-origin bottom
                    transform translateZ(0)
                    transition transform 0.6s $ease-out-quart
                    will-change transform
                }

                &:after {
                    top auto
                    bottom 0
                }

                .is-dark & {
                    opacity 0
                }

                .is-close &,
                .is-content-overlay & {
                    opacity 1

                    &:before {
                        transform: translateY(3px) rotate(45deg) translateZ(0)
                    }

                    &:after {
                        transform: translateY(-3px) rotate(-45deg) translateZ(0)
                    }
                }

                &--dark {
                    opacity 0
                    transform translateZ(0);
                    transition opacity 0.6s $ease-out-quart
                    will-change opacity

                    &:before, &:after {
                        background $colors['black'];
                    }

                    .is-dark & {
                        opacity 1
                    }

                    .is-close & {
                        opacity 0
                    }

                    // .is-content-overlay & {
                    //     opacity 0
                    // }
                }
            }
        }
    }
</style>