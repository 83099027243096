<template>
    <div>
        <slot/>
    </div>
</template>

<script>
    /* eslint no-console: ["error", { allow: ["warn", "error"] }] */

    export default {
        name: 'VideoObserver',

        created() {
            this.observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.$video.play();
                    } else {
                        this.$video.pause();
                    }
                });
            });
        },

        mounted() {
            this.$nextTick(() => {
                this.$video = this.$el.querySelector('.js-video');

                if(!this.$video) {
                    console.warn('VideoObserver: No video element defined (.js-video).')
                    return;
                }

                this.observer.observe(this.$video);
            });
        },

        beforeDestroy() {
            if(!this.$video) return;

            this.observer.unobserve(this.$video);
        },
    }
</script>