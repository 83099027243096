const actions = {
    togglePageReady(context, val) {
        context.commit('TOGGLE_PAGE_READY', val);
    },

    toggleNavigation(context, val) {
        context.commit('TOGGLE_NAVIGATION', val);
    },

    toggleIsSection(context, val){
        context.commit('TOGGLE_IS_SECTION', val);
    },

    toggleIsTransition(context, val){
        context.commit('TOGGLE_IS_TRANSITION', val);
    },

    toggleDownloadPanelOpen(context, val){
        context.commit('TOGGLE_DOWNLOAD_PANEL_OPEN', val);
    },

    toggleDownloadPanelActive(context, val){
        context.commit('TOGGLE_DOWNLOAD_PANEL_ACTIVE', val);
    },

    setActiveContentOverlay(context, val){
        context.commit('SET_ACTIVE_CONTENT_OVERLAY', val);
    },

    toggleContentOverlayOpen(context, val){
        context.commit('TOGGLE_CONTENT_OVERLAY_OPEN', val);
    },

    toggleCookiePopup(context, val) {
        context.commit('TOGGLE_COOKIE_POPUP', val);
    },

    setCurrentNav(context, val) {
        context.commit('SET_CURRENT_NAV', val);
    },

    setCurrentVideo(context, val) {
        context.commit('SET_CURRENT_VIDEO', val);
    },
};

export default actions;
