import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
    width: window.innerWidth,
    height: window.innerHeight,
    minHeight: window.innerHeight,

    showOrientationView: window.matchMedia("(orientation: landscape) and (max-device-height : 896px)").matches,
    isTouch: 'ontouchstart' in document.documentElement,
    isIE: (!!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g)),
    isAndroid: (navigator.userAgent.toLowerCase().indexOf("android") > -1),
    isFF: navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
};

const store = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default store;
