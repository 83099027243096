<template>
  <div v-bind:class="{ 'is-vertical': vertical }" class="c-ContentOverlay u-fixed">
    <virtual-scroll
      ref="virtualScroll"
      v-bind:active="contentOverlayOpen"
			v-bind:class="{ 'u-fit': !vertical }"
      class="u-relative"
			v-bind:style="`height: ${vertical && height}px;`"
    >
      <div v-bind:class="{ 'c-Container': vertical }" class="c-ContentOverlay-inner"  v-bind:style="`height: ${vertical && height}px;`">
        <slot></slot>
      </div>
    </virtual-scroll>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import VirtualScroll from '@/components/VirtualScroll';

import { disableScroll, enableScroll } from '@/utilities/disableScroll';

export default {
	name: 'ContentOverlay',

	props: {
		number: { type: Number, value: -1 },
		vertical: { type: Boolean, value: false },
	},

	components: {
		VirtualScroll,
	},

	computed: {
		...mapState('window', [
				'height',
		]),

		...mapState('main',[
			'activeContentOverlay',
			'contentOverlayOpen'
		]),
	},

	watch: {
		contentOverlayOpen: 'onToggleContentOverlayOpen',
	},

	methods: {
		open() {
			this.$el.classList.add('is-active');

			setTimeout(() => {
				// this.$el.focus();
				this.$el.classList.add('is-appear')
			}, 500);

			disableScroll();
		},

		close() {
			this.$el.classList.remove('is-active');

			setTimeout(() => {
				this.$el.classList.remove('is-appear');
				this.setActiveContentOverlay(-1);
				enableScroll();
			}, 800);
		},

		onToggleContentOverlayOpen() {
			if (this.number !== this.activeContentOverlay) return;

			if (!this.contentOverlayOpen) this.close();
			if (this.contentOverlayOpen) this.open()
		},

		onFocusOut() {
			// this.toggleContentOverlayOpen(!this.contentOverlayOpen)
			// this.close();
		},

		...mapActions('main', [
			'setActiveContentOverlay',
			'toggleContentOverlayOpen',
		]),
	},
};
</script>

<style lang='stylus'>
.c-ContentOverlay {
	top 0
	z-index 1002
	right 0
	width 67.77777778%
	height 100%
	visibility hidden
	will-change visibility
	transition visibility 1s $ease-out-quart

	&:before {
		content ''
		position absolute
		top:0
		left 0
		width 100%
		height 100%
		background #fff

		transform-origin bottom right
		transform skew(-10deg) scaleX(0) translateZ(0)
		transition transform 1s $ease-in-out-quart
	}

	&.is-active {
		opacity 1
		visibility visible

		&:before {
			transform skew(0deg) scaleX(1) translateZ(0)
		}

		.c-VirtualScroll-progression {
			opacity 1
		}
	}

	&.is-vertical {
		width 100%
		visibility hidden
		opacity 0

		&::before {
			opacity 0
			transform skewY(-7deg) scaleY(0)  translateZ(0)
			transition transform 0.8s $ease-in-out-quart
		}
	}

	&.is-vertical.is-active {
		visibility visible
		opacity 1

		&:before {
			opacity 1
			transform skewY(0deg) scaleY(1)  translateZ(0)
		}
	}

	&.is-vertical &-inner {
		width 100%
		min-height 100vh
		margin 0

		@media $breakpoints.sm {
			padding 0
		}
	}

	&-inner > * > * {
		opacity: 0;
		transform: translateY(80px) translateZ(0);
		will-change: transform, opacity;
		transition: opacity 0.35s $ease-in-quart, transform 0s linear 0.35s;

		.is-active & {
			opacity: 1;
			transform: translateZ(0);
		}

		for n in 0 .. 5 {
			&:nth-child({n}) {
				.is-active & {
					transition: transform 0.8s $ease-out-quart (0.5s + n * 0.1s), opacity 0.8s $ease-out-quart (0.5s + n * 0.1s);
				}
			}
		}

		@media $breakpoints.sm {
			transition: opacity 0.15s $ease-in-quart, transform 0s linear 0.15s;

			for n in 0 .. 5 {
				&:nth-child({n}) {
					.is-active & {
						transition: transform 0.8s $ease-out-quart (0.7s + n * 0.1s), opacity 0.8s $ease-out-quart (0.7s + n * 0.1s);
					}
				}
			}
		}
	}

	@media $breakpoints.lg {
		width: 73.61111111%;
	}

	@media $breakpoints.md {
		width: 79.58333333%;
	}

	@media $breakpoints.sm {
		width: 100%;
	}

	&-inner {
		width: calc((100vw - 80px) / 16 * 7);
		padding: 160px 2.272727273% 120px 0;
		margin-left: calc((100vw - 80px) / 16 * 2);

		@media $breakpoints.lg {
			width: calc((100vw - 80px) / 16 * 8.5);
			margin-left: calc((100vw - 80px) / 16 * 1.5);
		}

		@media $breakpoints.md {
			width: calc((100vw - 80px) / 16 * 12);
			margin-left: calc((100vw - 80px) / 16 * 1.5);
		}

		@media $breakpoints.sm {
			width: 100%;
			padding: 116px calc(4.8vw + 3.225806452%) 60px;
			margin-left: 0;
		}
	}

	.c-VirtualScroll {
		overflow unset
	}

	.c-VirtualScroll-progression {
		opacity: 0;
		transform: translateZ(0);
		will-change: opacity;
		transition: opacity 0.8s $ease-in-quart;
	}
}
</style>